export const dataBIWS = [
  {
    question: "What are the 3 financial statements?",
    answer:
      "1) Income Statement;\n\n2) Balance Sheet; and\n\n3)Cash Flow Statement",
    type: "Accounting",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "2a734d84-4672-4415-b70b-c2fc94ec18a1",
  },
  {
    question: "Income Statement",
    answer:
      "The Income Statement gives the company's revenue and expenses and goes all the way down to Net Income, the final line on the statement.\n\nExamples of major line items in Income Statements: \n-Revenue\n-Cost of Goods Sold\n-SG&A \n-Operating Income\n-Pretax Income\n-Net Income",
    type: "Accounting",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "042b3434-f204-461b-a5ad-35a44b95bfb2",
  },
  {
    question: "Balance Sheet",
    answer:
      "The Balance Sheet shows the company's \n\n1) Assets (its resources) - such as Cash, Inventory and PP&E;\n\n2) Liabilities - such as Debt, Accounts Payable, and Accrued Expenses; and\n\n3) Shareholder's Equity\n\nASSETS = LIABILITIES + SHAREHOLDERS EQUITY",
    type: "Accounting",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "e558928d-8fec-47f2-8e80-5f8741a208b8",
  },
  {
    question: "Cash Flow Statement",
    answer:
      "The Cash Flow Statement:\n- Begins with Net Income,\n\n- Adjusts for non-cash expenses and workings capital changes,\n\n- Then lists cash flow from investing and financing activities;\n\n- At the end you see the company's net change in cash\n\nItems in Cash Flow Statement Include:\n- Net Income; D&A, Stock-Based Comp; Changes in Operating Assets & Liabilities; Cash Flow from Operations; Capital Expenditures; Cash Flow from Investing; Sale/Purchase of Securities; Dividends Issued; Cash Flow From Financing",
    type: "Accounting",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "6c867c3c-a82f-4f09-aeaa-16a785411866",
  },
  {
    question: "How do the 3 financial statements link together?",
    answer:
      "Net Income from Income Statement flows into Shareholder's Equity on the Balance Sheet, and into the top line of the Cash Flow Statement\n\nChanges to Balance Sheet items appear as working capital changes on the Cash Flow Statement\n\nInvesting and Financing activities affect Balance Sheet items such as PP&E, Debt, and Shareholder's Equity.\n\nThe Cash and Shareholder's Equity items on the Balance Sheet act as \"plugs\" with Cash flowing in from the final line on the Cash Flow Statement.",
    type: "Accounting",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "3f4daf95-9727-42dc-822a-49ea7b32b01b",
  },
  {
    question:
      "If you only had 1 statement what is the best financial statement to use when reviewing the overall health of the company?",
    answer:
      "Use the Cash Flow Statement to assess the health of a company because it gives a true picture of how much cash the company is actually generating, independent of all the non-cash expenses you might have.\n\n- Cash flow is the #1 thing you care about when analyzing the overall health of any business",
    type: "Accounting",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "d0f96f8f-d11b-41ec-8b85-4dbdf37c9776",
  },
  {
    question:
      "If you only had 2 statements to assess a company's prospects, which 2 would you use?",
    answer:
      'You would pick the Income Statement and Balance Sheet because you can create the Cash Flow Statement from both of those.\n - assuming you have "before" and "after" \n versions of the Balance Sheet that \n correspond to the same period the \n Income Statement is Tracking',
    type: "Accounting",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "2e0eb8dc-a94c-4eb7-b178-776d4392b369",
  },
  {
    question:
      "How does Depreciation going up by $10 affect the 3 financial statements?",
    answer:
      "1) Income Statement - Operating Income would decline by $10 and assuming a 40% tax rate, Net Income would go down by $6\n\n2) Cash Flow Statement - Net Income at the top goes down by $6, but the $10 depreciation is a non-cash expense that gets added back, so overall Cash Flow from Operations goes up by $4. \n\n3) Balance Sheet - PP&E goes down by $10 on Asset side because of Depreciation, and Cash is up by $4 from the Changes from the changes on the Cash Flow Statement.\n\nOver all Assets is down by $6. Since Net Income fell by $6 as well, Shareholders' Equity on the Liabilities & Shareholder's Equity side is also down by $6 and both sides of the balance sheet balance.",
    type: "Accounting",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "5eaee22c-b695-486c-a08e-4864c6bb92dd",
  },
  {
    question: "How do Assets and Liabilities affect Cash Flow?",
    answer:
      "And increase in Assets causes a DECREASE in Cash Flow\n\nAnd an increase in Liabilities INCREASES Cash Flow",
    type: "Accounting",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "15d647ba-e9f3-409a-93b2-32e8f51fec59",
  },
  {
    question:
      "If Depreciation is a non-cash expense, why does it affect the cash balance?",
    answer:
      "Although Depreciation is a non-cash expense, it is tax-deductible. \n\nSince taxes are a cash expense, Depreciation affects cash by reducing the amount of taxes you pay.",
    type: "Accounting",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "6ee97d73-f767-4cc6-aa00-74f19d38ebd4",
  },
  {
    question:
      "Where does Depreciation usually show up on the Income Statement?",
    answer:
      "Deprecation can be shown as a separate line item.\n\nOR\n\nIt can be could be embedded in COGS or Operating Expenses\n\nAlthough every company does it differently. Note that the end result for accounting questions is the same:\n - Depreciation always reduces Pre-Tax \n Income",
    type: "Accounting",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "d1095d2e-125d-4d2b-9836-6bc92c935029",
  },
  {
    question: "What happens when Accrued Compensation goes up by $10?",
    answer:
      "First confirm that Accrued compensation is now being recognized as an expense \n -as opposed to just changing non- \n accrued to accrued compensation\n\nOn the Income Statement:\n- Operating Expenses on the Income Statement go up by $10;\n- Pre-Tax Income falls by $10; and \n- Net Income Falls by $6 (assuming $40% tax rate)\n\nOn the Cash Flow Statement:\n- Net Income is down by $6;\n- Accrued Compensation will INCREASE Cash Flow by $10 so overall Cash Flow from Operations is up by $4; and\n- Net Change in Cash at bottom is up by $4\n\nOn the Balance Sheet:\n- Cash is up by $4 therefore so are Assets\n- On Liabilities and Equity side, Accrued Compensation is a liability so Liabilities are up by $10 and Retained Earnings are down by $6 due to the Net Income so both sides balance",
    type: "Accounting",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "3ff86d93-c94a-4de7-acd9-59db9699e58d",
  },
  {
    question:
      "What happens when Inventory goes up by $10, assuming you pay for it with cash?",
    answer:
      "On the Income Statement:\n- No changes\n\nOn the Cash Flow Statement:\n-Inventory is an asset so that decreases your Cash Flow from Operations \n * it goes down by $10, as does the Net \n Change in Cash at the bottom\n\nOn the Balance Sheet Under Assets:\n- Inventory is up by $10 but Cash is down by $10 so the changes cancel out and Assets still Equals Liabilities & Shareholders' Equity",
    type: "Accounting",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "542a5428-9f76-438a-bdf8-37f97fedf65b",
  },
  {
    question:
      "Why is the Income Statement not affected by changes in inventory?",
    answer:
      "In the case of Inventory, the expense is only recorded when the goods associated with it are sold.\n\n- So if it's just sitting in a warehouse, it does not count as a COGS or Operating Expense until the company manufactures it into a product and sells it.",
    type: "Accounting",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "637287a6-a631-4586-8cbb-d9758779d33b",
  },
  {
    question:
      'If Apple buys $100 worth of new iPod Factories with debt, how are all 3 statements affected at the start of "Year 1" before anything else happens?',
    answer:
      "Start of Year 1\n\nOn Apple's Income Statement:\n- No changes\n\nOn the Cash Flow Statement:\n- The additional investment in factories would show up under the Cash Flow from Investing as a Net Reduction in Cash Flow\n * this means Cash Flow is down by $100\n- The additional $100 worth of debt raised would show up as an addition to Cash Flow, canceling out the investment activity\n\nOn the Balance Sheet:\n- There is now an additional $100 worth of factories in the PP&E line so PP&E is therefore up by $100.\n- On the other side, Debt is up by $100 as well and so both sides balance",
    type: "Accounting",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "ca828a70-8678-4eeb-ac00-090ba7d74a33",
  },
  {
    question:
      "Now go out 1 year to the start of year 2. Assume the debt is high-yield so no principal is paid off, and assume an interest rate of 10%. Also assume the factories depreciate at a rate of 10% per year. What happens?",
    answer:
      "After a year has passed, Apple must pay interest expense and must record the depreciation.\n\nOn Apple's Income Statement:\n- Operating Income decreases by 10% due to the 10% depreciation charge each year\n- Combined with the $10 in additional Interest Expense, Pre-Tax Income would decrease by $20\n * $10 from the depreciation and $10 \n from Interest Expense\n-Assuming a tax rate of 40%, Net Income would fall by $12\n\nOn the Cash Flow Statement:\n- Net Income at the top is down $12.\n- Depreciation is a non-cash expense, so you add it back and the end result is that Cash Flow from Operations is down by $2.\n\nOn the Balance Sheet:\n- Under Assets, Cash is down by $2 and PP&E is down by $10 due to the depreciation, so overall Assets are down by $12.\n- On the other side, since Net Income was down by $12, Shareholder's equity is also down by $12 and both sides balance.\n\n-Debt number under Liabilities does not change since we've assumed none of the debt is actually paid back",
    type: "Accounting",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "602a7ded-dbd8-4c91-8438-bacd3cc99100",
  },
  {
    question:
      "At the start of Year 3, the Factories all break down and the value of the equipment is written down to $0. The loan must also be paid back now. Walk through what happens in the 3 statements.",
    answer:
      "After 2 years, the value of the factories is now $80 if we go with the 10% depreciation per year assumption. It is this $80 that we will write down in the 3 statements.\n\nOn the Income Statement:\n- The $80 write-down shows up in the Pre-Tax Income line. With a 40% tax rate, Net Income declines by $48\n\nOn the Cash Flow Statement:\n- Net Income is down by $48 but the write-down is a non-cash expense, so we add it back\n * and therefore Cash Flow from \n Operations increases by $32\n-There are no changes under Cash Flow from Investing\n-Under Cash Flow from Financing there is a $100 charge for the loan payback \n-Overall the Net Change in Cash Falls by $68\n\nOn the Balance Sheet:\n-Cash is now down by $68 and PP&E is down by $80 so Assets have decreased by $148 altogether.\n-Debt is down by $100 since it was paid pff, and since was down by $48, Shareholders equity is down by $48.\n\n-Together Liabilities and Shareholders' Equity are down by $148 and both sides balance.",
    type: "Accounting",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "1ad9cfb2-d886-4883-b111-06b80caaf771",
  },
  {
    question:
      "Assume a different scenario under which Apple is ordering $10 of additional iPod inventory, using cash on hand. They order the inventory, but they have not manufactured or sold anything yet - What happens to the 3 statements?",
    answer:
      "On the Income Statement:\n- No changes\n\nOn the Cash Flow Statement:\n- Inventory is up by $10, so Cash Flow from Operations decreases by $10. \n- No other changes so overall, Cash is down by $10\n\nOn the Balance Sheet:\n- Inventory is up by $10 and Cash is down by $10 so the Assets number stays the same and the Balance Sheet remains in balance.",
    type: "Accounting",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "60eb7f72-a6c9-412c-8ad6-d63df2a21b59",
  },
  {
    question:
      "Now lets says they sell the iPods for revenue of $20, at a cost of $10, walk me through the 3 statements under this scenario.",
    answer:
      "On the Income Statement:\n- Revenue is up by $20 and COGS is up by $10, so Gross Profit is up by $10 and Operating Income is up by $10 as well.\n-Assuming a 40% tax rate, Net Income is up by $6\n\nOn the Cash Flow Statement:\n- Net Income at the top is up by $6 and Inventory has decreased by $10 (since we just manufactured the inventory into real iPods), \n * which is a net addition to cash flow - \n so Cash Flow from operations is up by \n $16 overall\n- These are the only changes on the Cash Flow Statement, so Net Change in Cash is up by $16.\n\nOn the Balance Sheet:\n- Cash is up by $16 and Inventory is down by $10, so Assets is up by $6 overall.\n- On the other side, Net Income was up by $6 so Shareholders' Equity is up by $6 and both sides balance.",
    type: "Accounting",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "b9bd897c-9030-4b3a-8419-d8d263c70d31",
  },
  {
    question:
      "Can you ever end up with negative Shareholders' equity? What does it mean?",
    answer:
      "Negative Shareholders' Equity is common in 2 scenarios:\n\n1) Leveraged Buyouts with dividend recapitalizations\n - It means that the owner of the \n company has taken out a large portion \n of its equity (usually in the form of \n cash) which can sometimes turn the \n number negative\n\n2) It can also happen if the company has been losing money consistently and therefore has a declining Retained Earnings balance, which is a portion of Shareholder's Equity\n\n-It doesn't \"mean\" anything in particular, but it can be a cause for concern and possibly demonstrate that the company is struggling (in the 2nd scenario)\n\nNOTE - Shareholder's Equity never turns negative immediately after an LBO \n - It would only happen following a \n dividend recap or continued net \n losses.",
    type: "Accounting",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "da7db016-6d89-46e7-9ebd-3b36ff48beb5",
  },
  {
    question: "What is working capital and how is it used?",
    answer:
      'Working = Current - Current\nCapital Assets Liabilities\n \n\n- If WC is positive, it means a company can pay off its short-term liabilities with its short-term assets.\n\n- It is often presented as a financial metric and its magnitude and sign (negative or positive) tells you whether or not the company is "sound")\n\nBankers look at Operating Working Capital more commonly in models, and that is defined as:\n\nOperating Working Capital \n= (Current Assets - Cash & Cash Equivs) -\n (Current Liabilities - Debt)',
    type: "Accounting",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "69f39bad-d6f2-4134-ae6e-32a0642abc76",
  },
  {
    question: "What does negative Working Capital mean? Is that a bad sign?",
    answer:
      "Negative Working Capital is not necessarily a bad sign. It depends on the type of company and the specific situation. Below are a few examples.\n\n1) Some companies with subscriptions or longer-term contracts often have negative Working Capital because of Deferred Revenue balances.\n\n2) Retail and restaurant companies like Amazon, Wal-Mart, and McDonald's often have negative Working Capital because customers pay upfront.\n * so they use they can use the cash \n generated to pay off their Accounts \n Payable rather than keeping a large \n cash balance on-hand. This can be a \n sign of business efficiency.\n\n3) In other cases, Negative Working Capital could point to financial trouble or possible bankruptcy \n - for example, when customers don't \n pay quickly and upfront and the \n company is carrying a high debt \n balance",
    type: "Accounting",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "3d80c6d8-3b0f-4e31-9b9d-0262d76a009b",
  },
  {
    question:
      "Recently, banks have been writing down their assets and taking huge quarterly losses. Walk me through what happens on the 3 statements when there's a write-down of $100.",
    answer:
      "On the Income Statement:\n- The $100 write-down shows up in the Pre-Tax Income line. With a 40% tax rate, Net income declines by $60.\n\nOn the Cash Flow Statement:\n- Net Income is down by $60 but the write-down is a non-cash expense, so we add it back\n * and therefore Cash Flow from \n Operations increases by $40.\n-Overall the Net Change in Cash rises by $40\n\nOn the Balance Sheet:\n- Cash is now up by $40 and an asset is down by $100\n * It's not clear which asset since the \n question never stated the specific \n asset to write-down\n-Over all the Assets side is down by $60\n-On the other side, Net Income was down by $60, so Shareholders' Equity is also down by $60 - and both sides balance",
    type: "Accounting",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "703bfe86-d95d-4752-a3a9-9cfc42c008dc",
  },
  {
    question:
      'Walk me through a $100 "bailout" of a company and how it affects the 3 statements.',
    answer:
      "First confirm what type of \"bailout\" this is\n - Debt?\n - Equity?\n - Combination?\n\nThe most common scenario is an equity investment from the government so here's what happens:\n\nOn the Income Statement:\n- No changes\n\nOn the Cash Flow Statement:\n- Cash Flow from Financing goes up by $100 to reflect the government's investment,\n * So the Net Change in Cash is up by \n $100 \n\nOn the Balance Sheet:\n- Cash is up by $100 so Assets are up by $100\n- On the other side, Shareholders' Equity would go up by $100 to make it balance",
    type: "Accounting",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "677b2380-49a7-45f7-b60a-c22ad1d12bc5",
  },
  {
    question:
      "Walk me through a $100 write-down of Debt - as in OWED debt, a liability - on a company's balance sheet and how it affects the 3 financial statements.",
    answer:
      "On the Income Statement:\n- This is counter-intuitive. When a liability is written down you record is as a gain on the Income Statement\n * With an asset write-down, it's a loss\n- So Pre-Tax Income goes up by $100 due to this write-down.\n-Assuming a 40% tax rate, Net Income is up by $60\n\nOn the Cash Flow Statement:\n- Net Income is up by $60, but we need to subtract that debt write-down\n * So Cash Flow from Operations is \n down by $40 \n- The Net Change in Cash is down by $40\n\nOn the Balance Sheet:\n- Cash is down by by $40 so Assets are down by $40\n- On the other side, Debt is down by $100 but Shareholders' Equity is up by $60\n- This makes Liabilities & Shareholders' Equity down by $40 and it balances",
    type: "Accounting",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "a9103cf8-bd8a-4192-b1c2-bc686ac0815f",
  },
  {
    question:
      "When would a company collect cash from a customer and not record it as revenue?",
    answer:
      "Three examples:\n1) Web-based subscription software\n2) cell phone carriers that sell annual contracts\n3) Magazine publishers that sell subscriptions\n\n- Companies that agree to services in the future often collect cash upfront to ensure stable revenue\n * This makes investors happy as well \n since they can better predict a \n company's performance\n\n- Per the rules of GAAP (Generally Accepted Accounting Principles), you only record revenue when you actually perform the services\n * So the company would not record everything as revenue right away",
    type: "Accounting",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "8f545a46-e966-4203-a7c1-784b88e14b63",
  },
  {
    question:
      "If cash collected is not recorded as revenue, what happens to it?",
    answer:
      '- Usually it goes into the Deferred Revenue balance on the Balance Sheet under Liabilities\n\n- Over time, as the services are performed, the Deferred Revenue balance "turns into" real revenue on the Income Statement',
    type: "Accounting",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "1311cedd-b58b-4424-b8c8-41978ef0ebd2",
  },
  {
    question:
      "Whats the difference between Accounts Receivable and Deferred Revenue?",
    answer:
      "Accounts Receivable is cash that has not yet been collected from customers \n * Accounts Receivable represents how much revenue the company is waiting on\n\nDeferred Revenue is cash that has been collected from customers\n * Deferred Revenue represents how \n much it is waiting to record as revenue",
    type: "Accounting",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "d04e289d-b291-4e88-a6c8-90881d4898e8",
  },
  {
    question:
      "How long does it usually take for a company to collect its accounts receivable balance?",
    answer:
      "Generally the Accounts Receivable days are in the 40-50 range\n\n- can be higher for companies selling high-end items\n\n-can be lower for smaller, lower transaction-value companies",
    type: "Accounting",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "13231eba-3b43-4372-ba56-8814a54e3bcb",
  },
  {
    question:
      "What's the difference between cash-based and accrual accounting?",
    answer:
      "Cash Based Accounting:\n- Recognizes revenue and expenses when cash is actually received or paid out\n\nAccrual Accounting:\n- Accrual accounting recognizes revenue when collection of revenue is reasonably certain \n * i.e. after a customer has ordered the product\n- Expenses are recognized when they are incurred rather than when they are paid out in cash\n\n- Most large companies use accrual accounting because paying with credit cards and lines of credit is so prevalent these days\n\n- Very small businesses may use cash-based accounting to simplify their financial statements",
    type: "Accounting",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "f5693d48-3276-4966-9e6d-b1a08e4eb5f4",
  },
  {
    question:
      "Lets say a customer pays for a TV with a credit card. What would this look like under cash-based vs. accrual accounting?",
    answer:
      "Cash-Based Accounting:\n- The revenue would not show up until all \n of the following happen \n 1) the company charges the customer's \n credit card;\n 2) receives authorization; and\n 3) deposits funds in its bank account\n- At this point, it would shod up as both Revenue on the income statement and Cash on the Balance Sheet\n\nAccrual Accounting:\n- It shows up as Revenue right away but instead of appearing as Cash on the Balance Sheet, it would go into Accounts Receivable at first.\n- Once the cash is actually deposited in the company's bank account, it would \"turn into\" Cash.",
    type: "Accounting",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "1666bfeb-dc48-442a-bd57-a7c8d654aa96",
  },
  {
    question:
      "How do you decide when to capitalize rather than expense a purchase?",
    answer:
      "- If the asset has a useful life of over 1 year, it is capitalized (put on the Balance Sheet rather than shown as an expense on the Income Statement)\n\n- Then it is depreciated (if a tangible asset) or amortized (if an intangible asset) over a certain amount of years\n\n- Purchases like factories, equipment and land all last longer than a year and therefore show up on the Balance Sheet. \n\n-Employee salaries and the cost of manufacturing products (COGS) only cover a short period of operations and therefore show up on the Income Statement as normal expenses instead.",
    type: "Accounting",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "030b7b25-81cc-458b-a4b9-2d8f5d543be4",
  },
  {
    question:
      'Why do companies report both GAAP and non-GAAP (or "Pro Forma") earnings?',
    answer:
      'These days, many companies have "non-cash" charges such as Amortization of Intangibles, Stock-Based Compensation, and Deferred Revenue Write-down in their Income Statements. \n\nAs a result, some argue that Income Statements under GAAP no longer reflect how profitable most companies truly are.\n\nNon-GAAP earnings are almost always higher because these expenses are excluded.',
    type: "Accounting",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "2e1a7bdc-706c-42c5-a899-1e7ad9f93564",
  },
  {
    question:
      "A company has had positive EBITDA for the past 10 years, but it recently went bankrupt. How could this happen?",
    answer:
      'Possible Scenarios:\n1) The company is spending too much on Capital Expenditures \n * These are not reflected at all in \n EBITDA, but it could still be cash-flow \n negative\n2) The company has high interest expense \n and is not longer able to afford its debt.\n3) The company\'s debt all matures on one \n date and it is unable to refinance it due \n to a "credit crunch" - and it runs out of \n cash completely when paying back the \n debt.\n4) It has significant one-time charges \n (from litigation, for example) and those \n are high enough to bankrupt the \n company\n\nRemember that EBITDA excludes investment in (and depreciation of) long-term assets, interest and one-time charges - and all of these could end up bankrupting the company.',
    type: "Accounting",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "c0002254-56f5-41f4-b86b-32e42414dbcc",
  },
  {
    question:
      "Normally Goodwill remains constant on the Balance Sheet - why would it be impaired and what does Goodwill Impairment mean?",
    answer:
      '-Usually Goodwill impairment happens when a company has been acquired and the acquirer re-assesses its intangible assets (such as customer relationships, trademarks/trade names, and intellectual property) and finds that they are worth significantly less than what they originally thought.\n\n- It often happens in acquisitions where the buyer "overpaid" for the seller and can result in a large net loss on the Income Statement.\n\n- It can also happen when a company discontinues part of its operations and must impair the associated goodwill.',
    type: "Accounting",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "e4ea3f9f-3be8-4437-a938-84410d252a94",
  },
  {
    question: "Under what circumstances would Goodwill increase?",
    answer:
      'Technically, Goodwill can increase if the company re-assess its value and finds that it is worth more, but that is rare. What usually happens is 1 of 2 scenarios:\n\n1) the company gets acquired or bought \n out and Goodwill changes as a result\n * Since its an accounting "plug" for the \n purchase price in an acquisition\n\n2) The company acquires another \n company and pays more than what its \n assets are worth - this is then reflected \n in the Goodwill number',
    type: "Accounting",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "d55e8c94-6eb0-4882-93ae-b9a4f71a4f65",
  },
  {
    question: "How is GAAP accounting different from tax accounting?",
    answer:
      "1) GAAP accounting is accrual-based but \n tax accounting is cash-based\n\n2) GAAP uses straight-line depreciation or \n a few other methods whereas tax \n accounting uses accelerated \n depreciation\n\n3) GAAP is more complex and more accurately tracks assets/liabilities whereas tax accounting is only concerned with revenue/expenses in the current period and what income tax you owe",
    type: "Accounting",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "8c20d196-e07a-449d-8607-e3bfeb686f07",
  },
  {
    question: "What are deferred tax assets/liabilities and how do they arise?",
    answer:
      "DTLs and DTAs arise because of temporary differences between what a company can deduct for cash tax purposes vs. what they can deduct for book tax purposes.\n\nDeferred Tax Liabilities:\n- DTLs arise when you have a tax expense on the Income Statement but haven't actually paid that tax in cold, hard cash yet.\n\nDeferred Tax Assets:\n- DTAs arise when you pay taxes in cash but haven't expensed them on the Income Statement yet.\n\nThe most common way they occur is with asset write-ups and write-downs in M&A deals - an asset write-up will produce a deferred tax liability while a write-down will produce a deferred tax asset.",
    type: "Accounting",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "a15e1d78-5486-453b-97b9-93f2b817b0a2",
  },
  {
    question: "Walk me through how you create a revenue model for a company.",
    answer:
      'There are 2 ways you could do this:\n\n1) Bottoms-Up Build\n - Start with individual \n products/customers \n- estimate the average sale value or \n customer value\n- and then the growth rate in sales and \n sales values to tie everything together\n\n2) Tops-Down Build\n- Start with the "big-picture" metrics like \n overall market size \n- then estimate the company\'s market \n share and how that will change in the \n coming years\n- and then multiply to get their revenue\n\nOf these two methods, Bottoms-Up is more common and is taken more seriously because estimating "big-picture" numbers is almost impossible.',
    type: "Accounting",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "eb7aff9e-5438-4376-b7c7-ddb02992fbe0",
  },
  {
    question: "Walk me through how you create an expense model for a company.",
    answer:
      'To do a true Bottoms-Up build, you:\n\n- Start with each different department of a company, the # of employees in each, the average salary, bonuses and benefits. \n\n-Then make assumptions on those going forward.\n\n- Usually you assume that the number of employees is tied to revenue, and then you assume growth rates for salary, bonuses, benefits, and other metrics.\n\n- COGS should be tied directly to Revenue and each "unit" produced should incur an expense.\n\n- Other items such as rent, Capex , and miscellaneous expenses are either linked to the company\'s internal plans for building expansion plans (if they have them), or to Revenue for a more simple model.',
    type: "Accounting",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "3e2b0d1e-e8dd-480f-8a01-ea80159ab6f9",
  },
  {
    question: "Walk me through the major items in Shareholders' Equity.",
    answer:
      'Common Items include:\n\nCommon Stock \n- Simply the par value of however much stock the company has issue\n\nRetained Earnings\n- How much of the company\'s Net Income it has "saved up" over time\n\nAdditional Paid-In Capital (APIC)\n- This keeps track of how much stock-based compensation has been issues and how much new stock employees exercising options have created. It also includes how much over par value a company raises in an IPO or other equity offering.\n\nTreasury Stock\n- The dollar amount of shares that the company has bought back.\n\nAccumulated Other Comprehensive Income\n- This is a "catch-all" that includes other items that don\'t fit anywhere else, like the effect of foreign currency exchange rates changing.',
    type: "Accounting",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "26d97558-e41e-4628-83a6-3b3e86cb8724",
  },
  {
    question: "Walk me through what flows into Retained Earnings.",
    answer:
      "R.E. = Old Retained Earnings Balance \n + Net Income\n - Dividend Issued\n\nif you're calculating Retained Earning for the current year, take last year's Retained Earnings number, add this year's Net Income, and subtract however much the company paid out in dividends.",
    type: "Accounting",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "01d08333-1239-4f33-9e65-5c848d804e1f",
  },
  {
    question:
      "Walk me through what flows into Additional Paid-In Capital (APIC)",
    answer:
      "APIC = Old APIC\n + Stock-Based Compensation\n + Stock Created by Option Exercises\n\nIf you're calculating APIC, take the APIC balance from last year, add this year's stock-based compensation number, and then add in however much new stock was created by employees exercising options this year.",
    type: "Accounting",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "e17b28b0-f0e4-4f1e-8fee-729de03e2123",
  },
  {
    question:
      "What is the Statement of Shareholders' Equity and why do we use it?",
    answer:
      "The Statement of Stockholders' Equity shows everything we went through previously - the major items that comprise \nShareholders' Equity and how we arrive at each of them using the numbers elsewhere in the statement.\n\nYou don't use it too much, but it can be helpful for analyzing companies with unusual stock-based compensation and stock option situations.",
    type: "Accounting",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "12dd2d80-9fe9-4c78-8b3f-f77a296d0703",
  },
  {
    question:
      "What are some examples of non-recurring charges that we need to add back to a company's EBIT/EBITDA when looking at its financial statements?",
    answer:
      '- Restructuring Charges\n- Goodwill Impairment\n- Asset Write-Downs\n- Bad Debt Expenses\n- Legal Expenses\n- Disaster Expenses\n- Change in Accounting Procedures\n\nNote that to be an "add-back" or "non-recurring" charge for EBIT/EBITDA purposes, ** it needs to affect Operating Income on the Income Statement.**\n\nSo if you have charges "below the line" then you do not need to add it back for the EBIT/EBITDA calculation.\n\nAlso note that you do add back Depreciation, Amortization, and sometimes Stock-Based Compensation for EBIT/EBITDA, but that that these are not "non-recurring charges" because all companies have them every year \n * These are just non-cash charges',
    type: "Accounting",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "81efa5cf-abc0-4e7d-9fd6-abe5c695c0a3",
  },
  {
    question:
      "How do you project Balance Sheet items like Accounts Receivable and Accrued Expenses in a 3-Statement model?",
    answer:
      "Best practice is to make very simple assumptions here and assume these are percentages of revenue, operating expenses, or cost of goods sold.\n\nExamples:\n- Accounts Receivable: % of revenue\n- Deferred Revenue: % of revenue\n- Accounts Payable: % of COGS\n- Accrued Expenses: % of operating \n expenses or SG&A\n\nThey you either carry the same percentages across in future years or assume slight changes depending on the company.",
    type: "Accounting",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "3a03c644-0e0d-4b4b-a45b-fd0d62723daa",
  },
  {
    question: "How do you project Depreciation & Capital Expenditures?",
    answer:
      "The simple way:\n- Project each one as a % of revenue or previous PP&E balance\n\nThe more complex way:\n- Create a PP&E schedule that \n 1) Splits out different assets by their \n useful lives;\n 2) Assumes straight-line depreciation \n over each asset's useful life; \n 3) And then assumes capital \n expenditures based on what the \n company has invested historically.",
    type: "Accounting",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "0b7c70cd-1faf-4774-b3e5-ca8ab78d1539",
  },
  {
    question:
      "How do Net Operating Losses (NOLs) affect a company's 3 financial statements?",
    answer:
      'The "quick and dirty" way to do this:\n1) Reduce the Taxable Income by the \n portion of the NOLs that you can use \n each year\n2) Apply the same tax rate\n3) Subtract that new Tax number from your \n old Pretax Income number (which \n should stay the same\n\nHow it should be done:\n1) Create a book vs cash tax schedule \n where you calculate the Taxable \n Income based on NOLs\n2) Look at what you would pay in taxes \n without the NOLs\n3) Book the difference as an increase to \n the Deferred Tax Liability on the \n Balance Sheet\n\nThe proper method reflects the fact that you\'re saving on cash flow - since the DTL (a liability) is rising - but correctly separates the NOL impact into book vs. cash taxes.',
    type: "Accounting",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "10b88032-edf4-40d9-8b95-4e445ed71bee",
  },
  {
    question:
      "What's the difference between capital leases and operating leases?",
    answer:
      "Operating Leases:\n- Operating leases are used for short-term leasing of equipment and property\n- Operating leases show up as operating expenses on the Income Statement\n\nCapital Leases:\n- Capital leases are used for longer-term items and give the lessee ownership rights\n- Capital leases depreciate and incur interest payments and are counted as debt.\n\nA lease is a capital lease if any one of the following 4 conditions is true:\n1) if there is a transfer of ownership at the \n end of the term\n2) If there is an option to purchase the \n asset at a bargain price at the end of \n the term\n3) If the term of the lease is greater than \n 75% of the useful life of the asset\n4) If the present value of the lease \n payment is greater than 90% of the \n asset's fair market value",
    type: "Accounting",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "0c8ac582-d17b-4e73-b2f1-8d83eb6568a4",
  },
  {
    question:
      "Why would the Depreciation & Amortization number on the Income Statement be different from what's on the Cash Flow Statement?",
    answer:
      "This happens if D&A is embedded in other Income Statement Line items. \n\nWhen this happens, you need to use the Cash Flow Statement number to arrive at EBITDA because otherwise you're undercounting D&A.",
    type: "Accounting",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "cdd14ab1-40d1-4d21-a15f-e587bb151592",
  },
  {
    question: "Why do we look at both Enterprise Value and Equity Value",
    answer:
      "Enterprise Value:\n- Enterprise Value represents the value of the company that is attributable to all investors\n\nEquity Value:\n- Equity Value represents only the portion available to shareholders (equity investors).\n\nYou look at both because Equity Value is the number the public-at-large sees, while Enterprise Value represents its true value.",
    type: "Enterprise & Equity Value",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "ebf49748-18d4-4222-9cb6-4a7c2f0aeb0a",
  },
  {
    question:
      "When looking at an acquisition of a company, do you pay more attention to Enterprise or Equity Value?",
    answer:
      'Pay more attention to Enterprise Value because that is how much an acquirer really "pays" and includes the often mandatory debt repayment.',
    type: "Enterprise & Equity Value",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "5637b86e-cfe1-44e6-accb-f2b489a0b19a",
  },
  {
    question: "What's the formula for Enterprise Value?",
    answer:
      "EV = Equity Value\n + Debt \n + Preferred Stock\n + Minority Interest\n - Cash \n\n*This formula does not tell the whole story but should be good enough for interviews",
    type: "Enterprise & Equity Value",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "49e3da1b-65ac-417f-8107-2c2bddffe14a",
  },
  {
    question: "Why do you need to add Minority Interest to Enterprise Value?",
    answer:
      "Whenever a company owns over 50% of another company, it is required to report the financial performance of the other company as part of its own performance.\n\nSo even though it doesn't own 100% of the majority-owned subsidiary's financial performance.\n\nIn keeping with the \"apples-to-apples\" theme, you must add Minority Interest to get to Enterprise Value so that your numerator and denominator both reflect 100% of the majority-owned subsidiary.",
    type: "Enterprise & Equity Value",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "b91f5180-2252-4f5a-8396-5f1bed6e58f7",
  },
  {
    question: "How do you calculate fully-diluted shares?",
    answer:
      "Take the basic share count and add in the dilutive effect of stock options and any other dilutive securities, such as warrants, convertible debt or convertible preferred stock.\n\nTo calculate the dilutive effect of options, you can use the Treasury Stock Method.",
    type: "Enterprise & Equity Value",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "915ca7cf-0529-42af-af8d-bf5c4d14cb12",
  },
  {
    question:
      "Let's say a company has 100 Shares outstanding, at a share price of $10 each. It also has 10 options outstanding at an exercise price of $5 each - what is its fully diluted equity value?",
    answer:
      'Basic Equity Value = $1,000 (100 * $10)\n\nTo calculate the dilutive effect of the options:\n- First note that the 10 options are all "in-the-money" - i.e. their exercise price is less than the current share price.\n\n-When these options are exercised, there will be 10 new shares created - so the share count is now 110 rather than 100.\n\nHowever, this doesn\'t tell the whole story.\n\nIn order to exercise the options, we had to "pay" the company $5 for each option (the exercise price)\n\nAs a result it now has $50 in additional cash, which it now uses to buy back 5 of the new shares we created.\n\nSo the fully diluted share is $105 and the fully diluted equity value is $1,050.',
    type: "Enterprise & Equity Value",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "5439f8d3-b2a9-4c0c-a2e9-16b0d8525616",
  },
  {
    question:
      "Let's say a company has 100 shares outstanding, at a share price of $10 each. It also has 10 options outstanding at an exercise price of $15 each - What is its fully diluted equity value?",
    answer:
      "Fully diluted equity value = $1,000 \n(100 * $10)\n\nIn this case the exercise price of the options ($15) is above the current share price ($10), so they have no dilutive effect.",
    type: "Enterprise & Equity Value",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "164c20d7-e2f0-44d6-bf71-0952af950426",
  },
  {
    question:
      "Why do you subtract cash in the formula for Enterprise Value? Is that always accurate?",
    answer:
      'The "Official" reason:\nCash is subtracted from Enterprise Value because it is considered a non-operating asset and because Equity Value implicitly accounts for it.\n\nAlternative way to think about it:\n- In an acquisition, the buyer would "get" the cash of the seller, so it effectively pays less for the company based on how large its cash balance is.\n- Remember Enterprise Value tells us how much you would really have to "pay" to acquire another company.\n- It\'s not always accurate because technically you should be subtracting only excess cash - the amount of cash a company has above the minimum cash it requires to operate.',
    type: "Enterprise & Equity Value",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "e435faa1-af8b-41e2-a703-7420993d9d09",
  },
  {
    question:
      "Is it always accurate to add Debt to the Equity Value when calculating Enterprise Value?",
    answer:
      'In most cases you should add debt to the Equity Value when calculating Enterprise value because the terms of a debt agreement usually say that debt must be refinanced in an acquisition.\n\nIn most cases a buyer will pay off a seller\'s debt so it is accurate to say that any debt "adds" to the purchase price.\n\nHowever there could always be exceptions where the buyer does not pay off the debt but they are super rare.',
    type: "Enterprise & Equity Value",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "720063e4-34a7-40a5-8af8-93a95209c3c0",
  },
  {
    question:
      "Could a company have negative Enterprise Value? What would that mean?",
    answer:
      "Yes, a company can have negative Enterprise Value. \n\nThis can mean that the company has an extremely large cash balance, or an extremely low market capitalization (or both). You see it with:\n\n1) Companies on the brink of bankruptcy\n2) Financial institutions, such as banks, that \n have large cash balances.",
    type: "Enterprise & Equity Value",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "1528bcdd-56b6-408d-a7f2-2559a67c0c07",
  },
  {
    question: "Could a company have a negative Equity Value?",
    answer:
      "No, a company with a negative Equity Value is not possible because: \n1) you cannot have a negative share count; \n and\n2) you cannot have a negative share price",
    type: "Enterprise & Equity Value",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "8ea10d76-e01e-44b3-8274-f179c85ccacb",
  },
  {
    question: "Why do we add Preferred Stock to get to Enterprise Value?",
    answer:
      "Preferred Stock is added to get to Enterprise Value because:\n\n1) Preferred Stock pays out a fixed dividend; and \n\n2)preferred stock holders also have a higher claim to a company's assets than equity investors do.\n\nAs a result it is more similar to debt than common stock.",
    type: "Enterprise & Equity Value",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "6f787be2-d57b-47b9-9feb-4b6dae1a01cc",
  },
  {
    question:
      "A company has 1 million shares outstanding at a value of $100 per share. It also has $10 million of convertible bonds, with par value of $1,000 and a conversion price of $50. How do I calculate diluted shares outstanding?",
    answer:
      'This gets confusing because of the different units involved. \n\n1) First note that these convertible bonds are "in-the-money" because the company\'s share price is $100, but the conversion price is $50. \n * So we count them as additional shares \n rather than debt\n\n2) We need to divide the value of the convertible bonds ($10 million) by the par value ($1000) to figure out how many individual bonds we get:\n- $10 million / $1,000 = 10,000 convertible \n bonds \n\n3) We need to figure out how many shares this represents. the number of shares per bond is the par value divided by the conversion price\n- $1,000 / $50 = 20 shares per bond\n\nSo we have 200,000 new shares \n(20 * 10,000) created by the convertibles, giving us 1.2 million diluted shares outstanding.\n\n*** Do not use the Treasury Stock Method with convertibles because the company is not "receiving" any cash from us.',
    type: "Enterprise & Equity Value",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "49f5ae9d-fc51-4b50-98b0-0fb8696e738a",
  },
  {
    question:
      "What is the difference between Equity Value and Shareholders' Equity?",
    answer:
      "Equity Value:\n- Equity Value is the market value\n- Equity value can never be negative because shares outstanding and share prices can never be negative.\n\nShareholder's Equity:\n- Shareholder's Equity is the book value\n- Shareholder's Equity can be any value. \n\nFor healthy companies, Equity Value usually far Exceeds Shareholders' Equity.",
    type: "Enterprise & Equity Value",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "c7b2e841-2210-4261-b8b2-f2a1e350309a",
  },
  {
    question:
      "Can you describe a few of the additional items that might be a part of Enterprise Value, beyond Cash, Debt, Preferred Stock, and Noncontrolling Interests, and explain whether you add or subtract each one?",
    answer:
      'Everyone agrees that Cash should be subtracted and Debt should be added when calculating Enterprise Value, but when you get to more advanced items, treatment varies greatly between different banks and different groups.\n\nA more "complete" formula might be: Enterprise Value = Equity Value - Cash + Debt + Preferred Stock + Noncontrolling Interests - NOLs - Investments - Equity Investments + Capital Leases + Unfunded Pension Obligations and Other Liabilities.',
    type: "Enterprise & Equity Value",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "977d56a4-a4ec-4393-9709-199543eded84",
  },
  {
    question:
      "Wait a second, why might you add back Unfunded Pension Obligations but not something like Accounts Payable? Don't they both need to be repaid?",
    answer:
      "The distinctions are magnitude and source of funds. Accounts Payable, 99% of the time, is paid back via the company's cash flow from its normal business operations. And it tends to be relatively small.\n\nItems like Unfunded Pension Obligations, by contrast, usually require additional funding (e.g. the company raises Debt) to be repaid. These types of Liabilities also tend to be much bigger than Working Capital / Operational Asset and Liability items.",
    type: "Enterprise & Equity Value",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "56d37c0a-b384-49a2-b0a1-f19d7accf4ab",
  },
  {
    question:
      "Are there any exceptions to the rules about subtracting Equity Interests and adding Noncontrolling Interests when calculating Enterprise Value?",
    answer:
      "You pretty much always add Noncontrolling Interests because the financial statements are always consolidated when you own over 50% of another company.\n\nBut with Equity Interests, you only subtract them if the metric you're looking at does not include Net Income from Equity Interests (which only appears toward the bottom of the Income Statement).\n\nFor example, Revenue, EBIT, and EBITDA all exclude revenue and profit from Equity Interests, so you subtract Equity Interests.\n\nBut with Levered Free Cash Flow (Free Cash Flow to Equity), typically you're starting with Net Income Attributable to Parent... which already includes Net Income from Equity Interests.\n\nNormally you subtract that out in the CFO section of the Cash Flow Statement so you would still subtract Equity Interests if you calculate Free Cash Flow by going through all the items in that section.\n\nBut if you have not subtracted out Net Income from Equity Interests (if you've used some other formula to calculate FCF), you should not subtract it in the Enterprise Value calculation - you want to show its impact in that case.",
    type: "Enterprise & Equity Value",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "9ebee163-0e17-4032-a0fb-f02108011257",
  },
  {
    question:
      "Should you use the Book Value or Market Value of each item when calculating Enterprise Value?",
    answer:
      "Technically, you should use market value for everything. In practice, however, you usually use market value only for the Equity Value portion because it's difficult to determine market values for the rest of the items in the formula - so you take the numbers from the company's Balance Sheet.",
    type: "Enterprise & Equity Value",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "d9637ecd-5bd7-46f4-90a9-81464a9318bc",
  },
  {
    question: 'What percentage dilution in Equity Value is "too high?"',
    answer:
      "There's no strict \"rule\" here, but most bankers would say that anything over 10% is odd. If the basic Equity Value is $100 million and the diluted Equity Value is $115 million, you might want to check your calculations - it's not necessarily wrong, but over 10% dilution is unusual for most companies. And something like 50% dilution would be highly unusual.",
    type: "Enterprise & Equity Value",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "bbd6efcd-613c-4bfc-a55e-2cbe8f326f4b",
  },
  {
    question:
      "How do you factor in Convertible Preferred Stock in the Enterprise Value calculation?",
    answer:
      "The same way you factor in normal Convertible Bonds: if it's in-the-money, you assume that new shares get created, and if it's not in the money, you count it as Debt.",
    type: "Enterprise & Equity Value",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "9400bfbb-c0c3-4518-80c2-d41234b9ace1",
  },
  {
    question:
      "How do you factor in Restricted Stock Units (RSUs) and Performance Shares when calculating Diluted Equity Value?",
    answer:
      "RSUs should be added to the common share count, because they are just common shares. The only difference is that the employees who own them have to hold onto them for a number of years before selling them.\n\nPerformance Shares are similar to Convertible Bonds, but if they're not in-the- money (the share price is below the performance share price target), you do not count them as Debt - you just ignore them altogether. If they are in-the-money, you assume that they are normal common shares and add them to the share count.",
    type: "Enterprise & Equity Value",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "df5f594a-4365-40f1-be49-ff0ab1c6e0f6",
  },
  {
    question:
      "What's the distinction between Options Exercisable vs. Options Outstanding? Which one(s) should you use when calculating share dilution?",
    answer:
      "Options Exercisable vs. Options Outstanding: Normally companies put in place restrictions on when employees can actually exercise options - so even if there are 1 million options outstanding right now, only 500,000 may actually be exercisable even if they're all in-the-money.\n\nThere's no \"correct\" answer for which one to use here. Some people argue that you should use Options Outstanding because typically, all non-exercisable Options become exercisable in an acquisition, so that's the more accurate way to view it.",
    type: "Enterprise & Equity Value",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "24d05414-3fd5-4df4-9b7d-2a3426a2569c",
  },
  {
    question: "What are the 3 major valuation methodologies?",
    answer:
      "Comparable Companies, Precedent Transactions and Discounted Cash Flow Analysis.",
    type: "Valuation",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "e78c8c42-5f93-4d20-aebe-3b65bf2c2860",
  },
  {
    question:
      "Rank the 3 valuation methodologies from highest to lowest expected value.",
    answer:
      "Trick question - there is no ranking that always holds. In general, Precedent Transactions will be higher than Comparable Companies due to the Control Premium built into acquisitions.\n\nBeyond that, a DCF could go either way and it's best to say that it's more variable than other methodologies. Often it produces the highest value, but it can produce the lowest value as well depending on your assumptions.",
    type: "Valuation",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "45132d0c-35ae-4e81-af92-3407ca9f8cd4",
  },
  {
    question: "When would you not use a DCF in a Valuation?",
    answer:
      "You do not use a DCF if the company has unstable or unpredictable cash flows (tech or bio-tech startup) or when debt and working capital serve a fundamentally different role.\nFor example, banks and financial institutions do not re-invest debt and working capital is a huge part of their Balance Sheets - so you wouldn't use a DCF for such companies.",
    type: "Valuation",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "652e6e57-12bf-4c1e-988c-c654b5dd575a",
  },
  {
    question: "What other Valuation methodologies are there?",
    answer:
      "Other methodologies include:\n• Liquidation Valuation - Valuing a company's assets, assuming they are sold off and then subtracting liabilities to determine how much capital, if any, equity investors\nreceive\n• Replacement Value - Valuing a company based on the cost of replacing its assets\n• LBO Analysis - Determining how much a PE firm could pay for a company to hit a \"target\" IRR, usually in the 20-25% range\n• Sum of the Parts - Valuing each division of a company separately and adding them together at the end\n• M&A Premiums Analysis - Analyzing M&A deals and figuring out the premium that each buyer paid, and using this to establish what your company is worth\n• Future Share Price Analysis - Projecting a company's share price based on the P / E multiples of the public company comparables, then discounting it back to its present\nvalue",
    type: "Valuation",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "2ce132c4-c008-4029-b7ef-77e66131145e",
  },
  {
    question: "When would you use a Liquidation Valuation?",
    answer:
      "This is most common in bankruptcy scenarios and is used to see whether equity shareholders will receive any capital after the company's debts have been paid off. It is\noften used to advise struggling businesses on whether it's better to sell off assets separately or to try and sell the entire company.",
    type: "Valuation",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "6a428eae-39d4-4f28-8728-1e43b83d2a72",
  },
  {
    question: "When would you use Sum of the Parts?",
    answer:
      "This is most often used when a company has completely different, unrelated divisions - a conglomerate like General Electric, for example.\n\nIf you have a plastics division, a TV and entertainment division, an energy division, a consumer financing division and a technology division, you should not use the same set of Comparable Companies and Precedent Transactions for the entire company.\n\nInstead, you should use different sets for each division, value each one separately, and then add them together to get the Combined Value.",
    type: "Valuation",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "95f20521-ceda-408b-b306-2d02f70a2f59",
  },
  {
    question: "When do you use an LBO Analysis as part of your Valuation?",
    answer:
      "Obviously you use this whenever you're looking at a Leveraged Buyout - but it is also used to establish how much a private equity firm could pay, which is usually lower than what companies will pay.\n\nIt is often used to set a \"floor\" on a possible Valuation for the company you're looking at.",
    type: "Valuation",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "3ffdc968-5ae6-417e-8481-1ff57cd7a45a",
  },
  {
    question: "What are the most common multiples used in Valuation?",
    answer:
      "The most common multiples are EV/Revenue, EV/EBITDA, EV/EBIT, P/E (Share Price / Earnings per Share), and P/BV (Share Price / Book Value).",
    type: "Valuation",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "cde085df-6b42-4f23-8dfb-bd81811705e2",
  },
  {
    question: "What are some examples of industry-specific multiples?",
    answer:
      'Technology (Internet): EV / Unique Visitors, EV / Pageviews\n\nRetail / Airlines: EV / EBITDAR (Earnings Before Interest, Taxes, Depreciation, Amortization & Rent)\n\nEnergy: P / MCFE, P / MCFE / D (MCFE = 1 Million Cubic Foot Equivalent, MCFE/D = MCFE per Day), P / NAV (Share Price / Net Asset Value)\n\nReal Estate Investment Trusts (REITs): Price / FFO, Price / AFFO (Funds From Operations, Adjusted Funds From Operations)\n\nTechnology and Energy should be straightforward - you\'re looking at traffic and energy reserves as value drivers rather than revenue or profit.\n\nFor Retail / Airlines, you often remove Rent because it is a major expense and one that varies significantly between different types of companies.\n\nFor REITs, Funds From Operations is a common metric that adds back Depreciation and subtracts gains on the sale of property. Depreciation is a non-cash yet extremely large expense in real estate, and gains on sales of properties are assumed to be non-recurring, so FFO is viewed as a "normalized" picture of the cash flow the REIT is generating.',
    type: "Valuation",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "6990e4e6-875a-4f0e-9f60-51702d1980fd",
  },
  {
    question:
      "When you're looking at an industry-specific multiple like EV / Scientists or EV /\nSubscribers, why do you use Enterprise Value rather than Equity Value?",
    answer:
      'You use Enterprise Value because those scientists or subscribers are "available" to all the investors (both debt and equity) in a company. The same logic doesn\'t apply to everything, though - you need to think through the multiple and see which investors the particular metric is "available" to.',
    type: "Valuation",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "48b20b0a-bc53-4002-93c1-0d7a324ea25b",
  },
  {
    question: "Would an LBO or DCF give a higher valuation?",
    answer:
      "Technically it could go either way, but in most cases the LBO will give you a lower valuation.\n\nHere's the easiest way to think about it: with an LBO, you do not get any value from the cash flows of a company in between Year 1 and the final year - you're only valuing it based on its terminal value.\n\nWith a DCF, by contrast, you're taking into account both the company's cash flows in between and its terminal value, so values tend to be higher.\n\nNote: Unlike a DCF, an LBO model by itself does not give a specific valuation. Instead, you set a desired IRR and determine how much you could pay for the company (the valuation) based on that.",
    type: "Valuation",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "b14eed01-7ca5-480b-8ccf-91a7ec5be5a3",
  },
  {
    question:
      "How would you present these Valuation methodologies to a company or its\ninvestors?",
    answer:
      'Usually you use a "football field" chart where you show the valuation range implied by\neach methodology. You always show a range rather than one specific number.\n\nAs an example, see page 10 of this document (a Valuation done by Credit Suisse for the Leveraged Buyout of Sungard Data Systems in 2005):\n\nhttp://edgar.sec.gov/Archives/edgar/data/789388/000119312505074184/dex99c2.htm',
    type: "Valuation",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "2a6b1764-825a-416f-8863-325a422c7b08",
  },
  {
    question: "How would you value an apple tree?",
    answer:
      "The same way you would value a company: by looking at what comparable apple trees are worth (relative valuation) and the value of the apple tree's cash flows (intrinsic valuation).\n\nYes, you could do a DCF for anything - even an apple tree.",
    type: "Valuation",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "ed7f0764-f0dc-48f8-bd37-181b4650508d",
  },
  {
    question:
      "Why can't you use Equity Value / EBITDA as a multiple rather than Enterprise Value / EBITDA?",
    answer:
      "EBITDA is available to all investors in the company - rather than just equity holders. Similarly, Enterprise Value is also available to all shareholders so it makes sense to pair them together.\n\nEquity Value / EBITDA, however, is comparing apples to oranges because Equity Value does not reflect the company's entire capital structure - only the part available to equity investors.",
    type: "Valuation",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "184dd98f-3152-4a68-8be5-24424d0b7871",
  },
  {
    question: "When would a Liquidation Valuation produce the highest value?",
    answer:
      "This is highly unusual, but it could happen if a company had substantial hard assets but\nthe market was severely undervaluing it for a specific reason (such as an earnings miss or cyclicality).\n\nAs a result, the company's Comparable Companies and Precedent Transactions would\nlikely produce lower values as well - and if its assets were valued highly enough, Liquidation Valuation might give a higher value than other methodologies.",
    type: "Valuation",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "92f69835-409a-42f8-ace5-4933c24afaf5",
  },
  {
    question:
      "Let's go back to 2004 and look at Facebook back when it had no profit and no revenue. How would you value it?",
    answer:
      'You would use Comparable Companies and Precedent Transactions and look at more "creative" multiples such as EV/Unique Visitors and EV/Pageviews rather than\nEV/Revenue or EV/EBITDA.\n\nYou would not use a "far in the future DCF" because you can\'t reasonably predict cash flows for a company that is not even making money yet.\n\nThis is a very common wrong answer given by interviewees. When you can\'t predict cash flow, use other metrics - don\'t try to predict cash flow anyway!',
    type: "Valuation",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "5b24e25e-987d-4180-93a9-d590c1c66ace",
  },
  {
    question:
      "What would you use in conjunction with Free Cash Flow multiples - Equity Value\nor Enterprise Value?",
    answer:
      'Trick question. For Unlevered Free Cash Flow, you would use Enterprise Value, but for Levered Free Cash Flow you would use Equity Value.\n\nRemember, Unlevered Free Cash Flow excludes Interest and thus represents money available to all investors, whereas Levered already includes Interest and the money is therefore only available to equity investors.\n\nDebt investors have already "been paid" with the interest payments they received.',
    type: "Valuation",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "c565d55e-68b9-4efe-9a8b-e05e1ad31f63",
  },
  {
    question:
      "You never use Equity Value / EBITDA, but are there any cases where you might\nuse Equity Value / Revenue?",
    answer:
      "Never say never. It's very rare to see this, but sometimes large financial institutions with big cash balances have negative Enterprise Values - so you might use Equity Value / Revenue instead.\n\nYou might see Equity Value / Revenue if you've listed a set of financial and nonfinancial companies on a slide, you're showing Revenue multiples for the non-financial companies, and you want to show something similar for the financials.\n\nNote, however, that in most cases you would be using other multiples such as P/E and P/BV with banks anyway.",
    type: "Valuation",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "d9da1da4-58f4-425a-8115-4eb6d5631c07",
  },
  {
    question:
      "How do you select Comparable Companies / Precedent Transactions?",
    answer:
      "The 3 main ways to select companies and transactions:\n\n1. Industry classification\n2. Financial criteria (Revenue, EBITDA, etc.)\n3. Geography\n\nFor Precedent Transactions, you often limit the set based on date and only look at transactions within the past 1-2 years.\n\nThe most important factor is industry - that is always used to screen for companies/transactions, and the rest may or may not be used depending on how specific you want to be.\n\nHere are a few examples:\n\nComparable Company Screen: Oil & gas producers with market caps over $5 billion\n\nComparable Company Screen: Digital media companies with over $100 million in revenue\n\nPrecedent Transaction Screen: Airline M&A transactions over the past 2 years involving sellers with over $1 billion in revenue\n\nPrecedent Transaction Screen: Retail M&A transactions over the past year",
    type: "Valuation",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "b0096f8e-b1a8-4cb8-b729-74fd9682472d",
  },
  {
    question:
      "How do you apply the 3 valuation methodologies to actually get a value for the company you're looking at?",
    answer:
      "Sometimes this simple fact gets lost in discussion of Valuation methodologies. You take\nthe median multiple of a set of companies or transactions, and then multiply it by the relevant metric from the company you're valuing.\n\nExample: If the median EBITDA multiple from your set of Precedent Transactions is 8x\nand your company's EBITDA is $500 million, the implied Enterprise Value would be $4\nbillion.\n\nTo get the \"football field\" valuation graph you often see, you look at the minimum, maximum, 25th percentile and 75th percentile in each set as well and create a range of values based on each methodology.",
    type: "Valuation",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "003f0ed3-8468-4ec2-a22d-f6007c3207ef",
  },
  {
    question: "What do you actually use a valuation for?",
    answer:
      'Usually you use it in pitch books and in client presentations when you\'re providing updates and telling them what they should expect for their own valuation.\n\nIt\'s also used right before a deal closes in a Fairness Opinion, a document a bank creates that "proves" the value their client is paying or receiving is "fair" from a financial point of view.\n\nValuations can also be used in defense analyses, merger models, LBO models, DCFs\n(because terminal multiples are based off of comps), and pretty much anything else in finance.',
    type: "Valuation",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "15f5c720-5044-450a-b3ed-efcb0b22f7d8",
  },
  {
    question:
      "Why would a company with similar growth and profitability to its Comparable Companies be valued at a premium?",
    answer:
      "This could happen for a number of reasons:\n• The company has just reported earnings well-above expectations and its stock price has risen recently.\n• It has some type of competitive advantage not reflected in its financials, such as a key patent or other intellectual property.\n• It has just won a favorable ruling in a major lawsuit.\n• It is the market leader in an industry and has greater market share than its competitors.",
    type: "Valuation",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "d943ba7b-3401-4b84-ba18-a236d4ea3351",
  },
  {
    question: "What are the flaws with public company comparables?",
    answer:
      '• No company is 100% comparable to another company.\n• The stock market is "emotional" - your multiples might be dramatically higher or lower on certain dates depending on the market\'s movements.\n• Share prices for small companies with thinly-traded stocks may not reflect their full value.',
    type: "Valuation",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "cfaf1991-4008-42dd-865f-46e04a9469c3",
  },
  {
    question:
      "How do you take into account a company's competitive advantage in a valuation?",
    answer:
      "1. Look at the 75th percentile or higher for the multiples rather than the Medians.\n2. Add in a premium to some of the multiples.\n3. Use more aggressive projections for the company.\n\nIn practice you rarely do all of the above - these are just possibilities.",
    type: "Valuation",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "cfe3b66d-4f89-468d-80d3-e45c2287a421",
  },
  {
    question:
      "Do you ALWAYS use the median multiple of a set of public company comparables\nor precedent transactions?",
    answer:
      "There's no \"rule\" that you have to do this, but in most cases you do because you want to use values from the middle range of the set. But if the company you're valuing is distressed, is not performing well, or is at a competitive disadvantage, you might use the\n25th percentile or something in the lower range instead - and vice versa if it's doing well.",
    type: "Valuation",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "747eca63-a3e2-41d6-9662-f61f165527c9",
  },
  {
    question:
      "You mentioned that Precedent Transactions usually produce a higher value than Comparable Companies - can you think of a situation where this is not the case?",
    answer:
      'Sometimes this happens when there is a substantial mismatch between the M&A market\nand the public market. For example, no public companies have been acquired recently\nbut there have been a lot of small private companies acquired at extremely low valuations.\n\nFor the most part this generalization is true but keep in mind that there are exceptions to\nalmost every "rule" in finance.',
    type: "Valuation",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "fb27b91b-f643-4b0b-a9c2-d9f952628f7c",
  },
  {
    question: "What are some flaws with precedent transactions?",
    answer:
      "• Past transactions are rarely 100% comparable - the transaction structure, size of the company, and market sentiment all have huge effects.\n• Data on precedent transactions is generally more difficult to find than it is for public company comparables, especially for acquisitions of small private\ncompanies.",
    type: "Valuation",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "86e6aeee-0e9e-4d2e-b247-2262004122c5",
  },
  {
    question:
      "Two companies have the exact same financial profile and are bought by the same acquirer, but the EBITDA multiple for one transaction is twice the multiple of the\nother transaction - how could this happen?",
    answer:
      "Possible reasons:\n1. One process was more competitive and had a lot more companies bidding on the\ntarget.\n2. One company had recent bad news or a depressed stock price so it was acquired at a discount.\n3. They were in industries with different median multiples.",
    type: "Valuation",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "527a3edd-fbf9-4a4d-80a3-46c1b21c1c55",
  },
  {
    question:
      "Why does Warren Buffett prefer EBIT multiples to EBITDA multiples?",
    answer:
      'Warren Buffett once famously said, "Does management think the tooth fairy pays for capital expenditures?"\n\nHe dislikes EBITDA because it excludes the often sizable Capital Expenditures companies make and hides how much cash they are actually using to finance their operations.\n\nIn some industries there is also a large gap between EBIT and EBITDA - anything that is very capital-intensive, for example, will show a big disparity.',
    type: "Valuation",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "6edeb4e0-8dc0-47b4-9185-7403c0005f73",
  },
  {
    question:
      "The EV / EBIT, EV / EBITDA, and P / E multiples all measure a company's profitability. What's the difference between them, and when do you use each one?",
    answer:
      "P / E depends on the company's capital structure whereas EV / EBIT and EV / EBITDA are capital structure-neutral. Therefore, you use P / E for banks, financial institutions, and other companies where interest payments / expenses are critical.\n\nEV / EBIT includes Depreciation & Amortization whereas EV / EBITDA excludes it - you're more likely to use EV / EBIT in industries where D&A is large and where capital expenditures and fixed assets are important (e.g. manufacturing), and EV / EBITDA in industries where fixed assets are less important and where D&A is comparatively smaller (e.g. Internet companies).",
    type: "Valuation",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "de9ffd2b-3305-4588-8da1-f507740362ac",
  },
  {
    question:
      "If you were buying a vending machine business, would you pay a higher multiple\nfor a business where you owned the machines and they depreciated normally, or one in which you leased the machines? The cost of depreciation and lease are the same dollar amounts and everything else is held constant.",
    answer:
      "You would pay more for the one where you lease the machines. Enterprise Value would\nbe the same for both companies, but with the depreciated situation the charge is not reflected in EBITDA - so EBITDA is higher, and the EV / EBITDA multiple is lower as a result. For the leased situation, the lease would show up in SG&A so it would be reflected in EBITDA, making EBITDA lower and the EV / EBITDA multiple higher.",
    type: "Valuation",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "ca2ad4d6-9d76-4ce7-b80a-a55c3dfd1b01",
  },
  {
    question: "How do you value a private company?",
    answer:
      "You use the same methodologies as with public companies: public company comparables, precedent transactions, and DCF. But there are some differences:\n• You might apply a 10-15% (or more) discount to the public company comparable multiples because the private company you're valuing is not as \"liquid\" as the public comps.\n• You can't use a premiums analysis or future share price analysis because a private company doesn't have a share price.\n• Your valuation shows the Enterprise Value for the company as opposed to the implied per-share price as with public companies.\n• A DCF gets tricky because a private company doesn't have a market capitalization or Beta - you would probably just estimate WACC based on the public comps' WACC rather than trying to calculate it.",
    type: "Valuation",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "37fe2b65-9a11-41d7-a4d3-7ba1f1354f14",
  },
  {
    question:
      "Let's say we're valuing a private company. Why might we discount the public company comparable multiples but not the precedent transaction multiples?",
    answer:
      "There's no discount because with precedent transactions, you're acquiring the entire company - and once it's acquired, the shares immediately become illiquid.\n\nBut shares - the ability to buy individual \"pieces\" of a company rather than the whole thing - can be either liquid (if it's public) or illiquid (if it's private).\n\nSince shares of public companies are always more liquid, you would discount public company comparable multiples to account for this.",
    type: "Valuation",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "ac7b67d8-461b-4123-acef-472ebbc9a3aa",
  },
  {
    question: "Can you use private companies as part of your valuation?",
    answer:
      "Only in the context of precedent transactions - it would make no sense to include them for public company comparables or as part of the Cost of Equity / WACC calculation in a DCF because they are not public and therefore have no values for market cap or Beta.",
    type: "Valuation",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "ee3c5c37-593c-43cb-b740-c03d6ee89c4e",
  },
  {
    question:
      "How do you value banks and financial institutions differently from other\ncompanies?",
    answer:
      "You mostly use the same methodologies, except:\n• You look at P / E and P / BV (Book Value) multiples rather than EV / Revenue, EV / EBITDA, and other \"normal\" multiples, since banks have unique capital structures.\n• You pay more attention to bank-specific metrics like NAV (Net Asset Value) and you might screen companies and precedent transactions based on those instead.\n• Rather than a DCF, you use a Dividend Discount Model (DDM) which is similar but is based on the present value of the company's dividends rather than its free cash flows.\n\nYou need to use these methodologies and multiples because interest is a critical\ncomponent of a bank's revenue and because debt is part of its business model rather than just a way to finance acquisitions or expand the business.",
    type: "Valuation",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "92a4c5c6-7d53-4d74-98aa-59092cf11e14",
  },
  {
    question:
      "Walk me through an IPO valuation for a company that's about to go public.",
    answer:
      '1. Unlike normal valuations, for an IPO valuation we only care about public company comparables.\n2. After picking the public company comparables we decide on the most relevant\nmultiple to use and then estimate our company\'s Enterprise Value based on that.\n3. Once we have the Enterprise Value, we work backward to get to Equity Value and also subtract the IPO proceeds because this is "new" cash.\n4. Then we divide by the total number of shares (old and newly created) to get its per-share price. When people say "An IPO priced at..." this is what they\'re\nreferring to.\n\nIf you were using P / E or any other "Equity Value-based multiple" for the multiple in step #2 here, then you would get to Equity Value instead and then subtract the IPO proceeds from there.',
    type: "Valuation",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "b9cba325-e00c-4eb7-9800-63ff542fb236",
  },
  {
    question:
      "I'm looking at financial data for a public company comparable, and it's April (Q2)\nright now. Walk me through how you would \"calendarize\" this company's financial statements to show the Trailing Twelve Months as opposed to just the last Fiscal Year.",
    answer:
      'The "formula" to calendarize financial statements is as follows:\n\nTTM = Most Recent Fiscal Year + New Partial Period - Old Partial Period \n\nSo in the example above, we would take the company\'s Q1 numbers, add the most recent fiscal year\'s numbers, and then subtract the Q1 numbers from that most recent fiscal year.\n\nFor US companies you can find these quarterly numbers in the 10-Q; for international companies they\'re in the "interim" reports.',
    type: "Valuation",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "c38e4266-74a1-4896-9d4e-425b830754fe",
  },
  {
    question: "Walk me through an M&A premiums analysis.",
    answer:
      "The purpose of this analysis is to look at similar transactions and see the premiums that\nbuyers have paid to sellers' share prices when acquiring them. For example, if a company is trading at $10.00/share and the buyer acquires it for $15.00/share, that's a 50% premium.\n\n1. First, select the precedent transactions based on industry, date (past 2-3 years for example), and size (example: over $1 billion market cap).\n2. For each transaction, get the seller's share price 1 day, 20 days, and 60 days before the transaction was announced (you can also look at even longer intervals, or 30 days, 45 days, etc.).\n3. Then, calculate the 1-day premium, 20-day premium, etc. by dividing the per-share purchase price by the appropriate share prices on each day.\n4. Get the medians for each set, and then apply them to your company's current share price, share price 20 days ago, etc. to estimate how much of a premium a buyer might pay for it.\n\nNote that you only use this analysis when valuing public companies because private companies don't have share prices. Sometimes the set of companies here is exactly the\nsame as your set of precedent transactions but typically it is broader.",
    type: "Valuation",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "85139647-ff3d-4e36-a357-83a58ca1b137",
  },
  {
    question: "Walk me through a future share price analysis.",
    answer:
      "The purpose of this analysis is to project what a company's share price might be 1 or 2 years from now and then discount it back to its present value.\n\n1. Get the median historical (usually TTM) P / E of your public company comparables.\n2. Apply this P / E multiple to your company's 1-year forward or 2-year forward projected EPS to get its implied future share price.\n3. Then, discount this back to its present value by using a discount rate in-line with the company's Cost of Equity figures.\n\nYou normally look at a range of P / E multiples as well as a range of discount rates for this type of analysis, and make a sensitivity table with these as inputs.",
    type: "Valuation",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "db3d399f-2d6d-4d46-a6bb-5a77a10b7ba1",
  },
  {
    question:
      "Both M&A premiums analysis and precedent transactions involve looking at previous M&A transactions. What's the difference in how we select them?",
    answer:
      "• All the sellers in the M&A premiums analysis must be public.\n• Usually we use a broader set of transactions for M&A premiums - we might use fewer than 10 precedent transactions but we might have dozens of M&A\npremiums. The industry and financial screens are usually less stringent.\n• Aside from those, the screening criteria is similar - financial, industry, geography, and date.",
    type: "Valuation",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "c85d91d0-0c20-46f0-bddb-d98787d9a39c",
  },
  {
    question: "Walk me through a Sum-of-the-Parts analysis.",
    answer:
      "In a Sum-of-the-Parts analysis, you value each division of a company using separate comparables and transactions, get to separate multiples, and then add up each division's\nvalue to get the total for the company. Example:\n\nWe have a manufacturing division with $100 million EBITDA, an entertainment division\nwith $50 million EBITDA and a consumer goods division with $75 million EBITDA. We've selected comparable companies and transactions for each division, and the median multiples come out to 5x EBITDA for manufacturing, 8x EBITDA for\nentertainment, and 4x EBITDA for consumer goods.\n\nOur calculation would be $100  5x + $50  8x + $75 * 4x = $1.2 billion for the company's total value.",
    type: "Valuation",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "cd674a16-1f07-49ed-9ef0-2e7d97affd79",
  },
  {
    question:
      "How do you value Net Operating Losses and take them into account in a valuation?",
    answer:
      "You value NOLs based on how much they'll save the company in taxes in future years, and then take the present value of the sum of tax savings in future years. Two ways to assess the tax savings in future years:\n\n1. Assume that a company can use its NOLs to completely offset its taxable income until the NOLs run out.\n2. In an acquisition scenario, use Section 382 and multiply the adjusted long-term rate (http://pmstax.com/afr/exemptAFR.shtml) by the equity purchase price of the seller to determine the maximum allowed NOL usage in each year - and then use that to figure out the offset to taxable income.\n\nYou might look at NOLs in a valuation but you rarely add them in - if you did, they would be similar to cash and you would subtract NOLs to go from Equity Value to Enterprise Value, and vice versa.",
    type: "Valuation",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "8bc6138e-2915-445e-bc43-8643c17b0fca",
  },
  {
    question:
      "I have a set of public company comparables and need to get the projections from equity research. How do I select which report to use?",
    answer:
      "This varies by bank and group, but two common methods: \n\n1. You pick the report with the most detailed information.\n2. You pick the report with numbers in the middle of the range.\n\nNote that you do not pick reports based on which bank they're coming from. So if you're at Goldman Sachs, you would not pick all Goldman Sachs equity research - in fact that would be bad because then your valuation would not be objective.",
    type: "Valuation",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "3e4e61df-6d99-4e35-a734-54ed313f894a",
  },
  {
    question:
      "I have a set of precedent transactions but I'm missing information like EBITDA for a lot of the companies - how can I find it if it's not available via public sources?",
    answer:
      "1. Search online and see if you can find press releases or articles in the financial press with these numbers.\n2. Failing that, look in equity research for the buyer around the time of the transaction and see if any of the analysts estimate the seller's numbers.\n3. Also look on online sources like Capital IQ and Factset and see if any of them disclose numbers or give estimates.",
    type: "Valuation",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "c6e0f907-05ad-4942-89b0-79434b47a52f",
  },
  {
    question:
      "How far back and forward do we usually go for public company comparable and\nprecedent transaction multiples?",
    answer:
      "Usually you look at the TTM (Trailing Twelve Months) period for both sets, and then you look forward either 1 or 2 years. You're more likely to look backward more than 1 year and go forward more than 2 years for public company comparables; for precedent transactions it's odd to go forward more than 1 year because your information is more limited.",
    type: "Valuation",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "e9bd3070-31b0-417d-a92c-a197bf2119c3",
  },
  {
    question:
      "I have one company with a 40% EBITDA margin trading at 8x EBITDA, and another company with a 10% EBITDA margin trading at 16x EBITDA. What's the\nproblem with comparing these two valuations directly?",
    answer:
      'There\'s no "rule" that says this is wrong or not allowed, but it can be misleading to compare companies with dramatically different margins. Due to basic arithmetic, the 40% margin company will usually have a lower multiple - whether or not its actual value is lower.\n\nIn this situation, we might consider screening based on margins and remove the outliers - you would never try to "normalize" the EBITDA multiples based on margins.',
    type: "Valuation",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "4c5fda6c-5e5a-4904-85d7-43d75cbe33f8",
  },
  {
    question:
      'Walk me through how we might value an oil & gas company and how it\'s different from a "standard" company.',
    answer:
      "You use the same methodologies, except: \n\n• You look at industry-specific multiples like P / MCFE and P / NAV in addition to the more standard ones.\n• You need to project the prices of commodities like oil and natural gas, and also the company's reserves to determine its revenue and cash flows in future years.\n• Rather than a DCF, you use a NAV (Net Asset Value) model - it's similar, but everything flows from the company's reserves rather than simple revenue\ngrowth / EBITDA margin projections.\n\nIn addition to all of the above, there are also some accounting complications with energy\ncompanies and you need to think about what a \"proven\" reserve is vs. what is more speculative.",
    type: "Valuation",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "0637aabf-1ea5-4250-b9a8-6228edb29037",
  },
  {
    question:
      'Walk me through how we would value a REIT (Real Estate Investment Trust) and how it differs from a "normal" company.',
    answer:
      "Similar to energy, real estate is asset-intensive and a company's value depends on how much cash flow specific properties generate.\n\n• You look at Price / FFO (Funds From Operations) and Price / AFFO (Adjusted Funds From Operations), which add back Depreciation and subtract gains on property sales; NAV (Net Asset Value) is also important.\n• You value properties by dividing Net Operating Income (NOI) (Property's Gross Income - Operating Expenses) by the capitalization rate (based on market data).\n• Replacement Valuation is more common because you can actually estimate the cost of buying new land and building new properties.\n• A DCF is still a DCF, but it flows from specific properties and it might be useless depending on what kind of company you're valuing.",
    type: "Valuation",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "cbb52934-c592-44f7-9178-2d1fffe1913d",
  },
  {
    question: "Walk me through a DCF",
    answer:
      "\"A DCF values a company based on the Present Value of its Cash Flows and the Present Value of its Terminal Value.\nFirst, you project out a company's financials using assumptions for revenue growth, expenses and Working Capital; then you get down to Free Cash Flow for each year, which you then sum up and discount to a Net Present Value, based on your discount rate - usually the Weighted Average Cost of Capital.\nOnce you have the present value of the Cash Flows, you determine the company's Terminal Value, using either the Multiples Method or the Gordon Growth Method, and then also discount that back to its Net Present Value using WACC.\nFinally, you add the two together to determine the company's Enterprise Value.\"",
    type: "DCF",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "2acd65be-3b10-47d1-9943-62811fdb51f9",
  },
  {
    question:
      "Walk me through how you get from Revenue to Free Cash Flow in the projections.",
    answer:
      "Subtract COGS and Operating Expenses to get to Operating Income (EBIT). Then, multiply by (1 - Tax Rate), add back Depreciation and other non-cash charges, and subtract Capital Expenditures and the change in Working Capital.\nNote: This gets you to Unlevered Free Cash Flow since you went off EBIT rather than EBT. You might want to confirm that this is what the interviewer is asking for.",
    type: "DCF",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "8db87fa1-0e21-4648-8b9a-be72074fbb40",
  },
  {
    question:
      "What's an alternate way to calculate Free Cash Flow aside from taking Net Income, adding back Depreciation, and subtracting Changes in Operating Assets / Liabilities and CapEx?",
    answer:
      "Take Cash Flow From Operations and subtract CapEx - that gets you to Levered Cash Flow. To get to Unlevered Cash Flow, you then need to add back the tax-adjusted Interest Expense and subtract the tax-adjusted Interest Income. Tax adjusted interest expense = interest * 1- tax rate",
    type: "DCF",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "ff4a6af4-3693-412c-89ea-db3f2f489c5e",
  },
  {
    question: "Why do you use 5 or 10 years for DCF projections?",
    answer:
      "That's usually about as far as you can reasonably predict into the future. Less than 5 years would be too short to be useful, and over 10 years is too difficult to predict for most companies.",
    type: "DCF",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "37dc2407-cc1c-46df-a931-a0a0f04252c5",
  },
  {
    question: "What do you usually use for the discount rate?",
    answer:
      "Normally you use WACC (Weighted Average Cost of Capital), though you might also use Cost of Equity depending on how you've set up the DCF.",
    type: "DCF",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "8d7cd1d9-f8be-4283-867f-f1a94582abed",
  },
  {
    question: "How do you calculate WACC?",
    answer:
      "The formula is: Cost of Equity  (% Equity) + Cost of Debt  (% Debt)  (1 - Tax Rate) + Cost of Preferred  (% Preferred).\nIn all cases, the percentages refer to how much of the company's capital structure is taken up by each component.\nFor Cost of Equity, you can use the Capital Asset Pricing Model (CAPM - see the next question) and for the others you usually look at comparable companies/debt issuances and the interest rates and yields issued by similar companies to get estimates",
    type: "DCF",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "6cf2f25b-62fa-4c8e-af5c-6df03de4c427",
  },
  {
    question: "How do you calculate the Cost of Equity?",
    answer:
      'Cost of Equity = Risk-Free Rate + Beta * Equity Risk Premium\nThe risk-free rate represents how much a 10-year or 20-year US Treasury should yield; Beta is calculated based on the "riskiness" of Comparable Companies and the Equity Risk Premium is the % by which stocks are expected to out-perform "risk-less" assets.\n\nNormally you pull the Equity Risk Premium from a publication called Ibbotson\'s. \n\nNote: This formula does not tell the whole story. Depending on the bank and how precise you want to be, you could also add in a "size premium" and "industry premium" to account for how much a company is expected to out-perform its peers is according to its market cap or industry.\nSmall company stocks are expected to out-perform large company stocks and certain industries are expected to out-perform others, and these premiums reflect these expectations.',
    type: "DCF",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "e6511b2f-434a-4213-acbe-da28cf232186",
  },
  {
    question: "How do you get to Beta in the Cost of Equity calculation?",
    answer:
      "You look up the Beta for each Comparable Company (usually on Bloomberg), un-lever each one, take the median of the set and then lever it based on your company's capital structure. Then you use this Levered Beta in the Cost of Equity calculation.\nFor your reference, the formulas for un-levering and re-levering Beta are below:\n\nUn-Levered Beta = Levered Beta / (1 + ((1 - Tax Rate) x (Total Debt/Equity))) \nLevered Beta = Un-Levered Beta x (1 + ((1 - Tax Rate) x (Total Debt/Equity)))",
    type: "DCF",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "bb08de64-a964-4ffa-9142-bdd689925517",
  },
  {
    question: "Levered",
    answer: "After company has met its financial obligations",
    type: "DCF",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "ad2aebb1-dea4-42f0-8a32-c871e80b31d9",
  },
  {
    question: "Why do you have to un-lever and re-lever Beta?",
    answer:
      'Again, keep in mind our "apples-to-apples" theme. When you look up the Betas on Bloomberg (or from whatever source you\'re using) they will be levered to reflect the debt already assumed by each company.\nBut each company\'s capital structure is different and we want to look at how "risky" a company is regardless of what % debt or equity it has.\nTo get that, we need to un-lever Beta each time.\nBut at the end of the calculation, we need to re-lever it because we want the Beta used in the Cost of Equity calculation to reflect the true risk of our company, taking into account its capital structure this time.',
    type: "DCF",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "9cf98a8b-1766-4ae4-bfc8-bbec6de686d2",
  },
  {
    question:
      "Would you expect a manufacturing company or a technology company to have a higher Beta?",
    answer:
      'A technology company, because technology is viewed as a "riskier" industry than manufacturing.',
    type: "DCF",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "17d935a6-3e39-4942-8d8e-04b7d27c257a",
  },
  {
    question:
      "Let's say that you use Levered Free Cash Flow rather than Unlevered Free Cash Flow in your DCF - what is the effect?",
    answer:
      'Levered Free Cash Flow gives you Equity Value rather than Enterprise Value, since the cash flow is only available to equity investors (debt investors have already been "paid" with the interest payments).',
    type: "DCF",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "aa0fb93b-de98-46df-ad4b-37da17f42e21",
  },
  {
    question:
      "If you use Levered Free Cash Flow, what should you use as the Discount Rate?",
    answer:
      "You would use the Cost of Equity rather than WACC since we're not concerned with Debt or Preferred Stock in this case - we're calculating Equity Value, not Enterprise Value.",
    type: "DCF",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "8dd750e6-cbfb-42ff-93cf-de00c691362d",
  },
  {
    question: "How do you calculate the Terminal Value?",
    answer:
      "You can either apply an exit multiple to the company's Year 5 EBITDA, EBIT or Free Cash Flow (Multiples Method) or you can use the Gordon Growth method to estimate its value based on its growth rate into perpetuity.\nThe formula for Terminal Value using Gordon Growth is: Terminal Value = Year 5 Free Cash Flow * (1 + Growth Rate) / (Discount Rate - Growth Rate).",
    type: "DCF",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "26eb8b05-1980-4923-9a0f-d50c15927668",
  },
  {
    question:
      "Why would you use Gordon Growth rather than the Multiples Method to calculate the Terminal Value?",
    answer:
      "In banking, you almost always use the Multiples Method to calculate Terminal Value in a DCF. It's much easier to get appropriate data for exit multiples since they are based on Comparable Companies - picking a long-term growth rate, by contrast, is always a shot in the dark.\nHowever, you might use Gordon Growth if you have no good Comparable Companies or if you have reason to believe that multiples will change significantly in the industry several years down the road. For example, if an industry is very cyclical you might be better off using long-term growth rates rather than exit multiples.",
    type: "DCF",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "3a0ace8f-cc20-40cf-8934-de07a9996776",
  },
  {
    question:
      "What's an appropriate growth rate to use when calculating the Terminal Value?",
    answer:
      "Normally you use the country's long-term GDP growth rate, the rate of inflation, or something similarly conservative.\nFor companies in mature economies, a long-term growth rate over 5% would be quite aggressive since most developed economies are growing at less than 5% per year.",
    type: "DCF",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "4bb5d3c8-b210-46d0-9218-a8ff01797404",
  },
  {
    question:
      "How do you select the appropriate exit multiple when calculating Terminal Value?",
    answer:
      "Normally you look at the Comparable Companies and pick the median of the set, or something close to it.\nAs with almost anything else in finance, you always show a range of exit multiples and what the Terminal Value looks like over that range rather than picking one specific number.\nSo if the median EBITDA multiple of the set were 8x, you might show a range of values using multiples from 6x to 10x.",
    type: "DCF",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "c89db788-5f66-4ab4-ac4d-408d5bef09f3",
  },
  {
    question:
      "Which method of calculating Terminal Value will give you a higher valuation?",
    answer:
      "It's hard to generalize because both are highly dependent on the assumptions you make. In general, the Multiples Method will be more variable than the Gordon Growth method because exit multiples tend to span a wider range than possible long-term growth rates.",
    type: "DCF",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "6d154a43-143f-4173-b159-00fa59256a6e",
  },
  {
    question:
      "What's the flaw with basing terminal multiples on what public company comparables are trading at?",
    answer:
      "The median multiples may change greatly in the next 5-10 years so it may no longer be accurate by the end of the period you're looking at. This is why you normally look at a wide range of multiples and do a sensitivity to see how the valuation changes over that range.\nThis method is particularly problematic with cyclical industries (e.g. semiconductors).",
    type: "DCF",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "12ac786d-e8c4-4b83-86ad-ee4ff8dbd017",
  },
  {
    question:
      "How do you know if your DCF is too dependent on future assumptions?",
    answer:
      'The "standard" answer: if significantly more than 50% of the company\'s Enterprise Value comes from its Terminal Value, your DCF is probably too dependent on future assumptions. In reality, almost all DCFs are "too dependent on future assumptions" - it\'s actually quite rare to see a case where the Terminal Value is less than 50% of the Enterprise Value.\nBut when it gets to be in the 80-90% range, you know that you may need to re-think your assumptions...',
    type: "DCF",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "f1696bd1-586f-47d9-b645-3b82362728f8",
  },
  {
    question:
      "Should Cost of Equity be higher for a $5 billion or $500 million market cap company?",
    answer:
      'It should be higher for the $500 million company, because all else being equal, smaller companies are expected to outperform large companies in the stock market (and therefore be "more risky"). Using a Size Premium in your calculation would also ensure that Cost of Equity is higher for the $500 million company.',
    type: "DCF",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "e21994c1-1de3-4c2c-8fae-83d0a65b9b48",
  },
  {
    question:
      "What about WACC - will it be higher for a $5 billion or $500 million company?",
    answer:
      "This is a bit of a trick question because it depends on whether or not the capital structure is the same for both companies. If the capital structure is the same in terms of percentages and interest rates and such, then WACC should be higher for the $500 million company for the same reasons as mentioned above.\nIf the capital structure is not the same, then it could go either way depending on how much debt/preferred stock each one has and what the interest rates are.",
    type: "DCF",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "7acd752a-ad25-4ae5-8806-928e57e7c6c8",
  },
  {
    question: "What's the relationship between debt and Cost of Equity?",
    answer:
      "More debt means that the company is more risky, so the company's Levered Beta will be higher - all else being equal, additional debt would raise the Cost of Equity, and less debt would lower the Cost of Equity.",
    type: "DCF",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "608ffb18-3f2b-4409-8e98-608314c85b59",
  },
  {
    question:
      "Cost of Equity tells us what kind of return an equity investor can expect for investing in a given company - but what about dividends? Shouldn't we factor dividend yield into the formula?",
    answer:
      "Trick question. Dividend yields are already factored into Beta, because Beta describes returns in excess of the market as a whole - and those returns include dividends.",
    type: "DCF",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "8fa917ca-2564-43d4-a580-3ff45fa2b904",
  },
  {
    question: "How can we calculate Cost of Equity WITHOUT using CAPM?",
    answer:
      'There is an alternate formula:\nCost of Equity = (Dividends per Share / Share Price) + Growth Rate of Dividends\nThis is less common than the "standard" formula but sometimes you use it for companies where dividends are more important or when you lack proper information on Beta and the other variables that go into calculating Cost of Equity with CAPM.',
    type: "DCF",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "95d4db0d-70b7-47b1-8c57-cb2afec51d8b",
  },
  {
    question:
      "Two companies are exactly the same, but one has debt and one does not - which one will have the higher WACC?",
    answer:
      'This is tricky - the one without debt will have a higher WACC up to a certain point, because debt is "less expensive" than equity. Why?\n\n• Interest on debt is tax-deductible (hence the (1 - Tax Rate) multiplication in the WACC formula).\n• Debt is senior to equity in a company\'s capital structure - debt holders would be paid first in a liquidation or bankruptcy.\n• Intuitively, interest rates on debt are usually lower than the Cost of Equity numbers you see (usually over 10%). As a result, the Cost of Debt portion of WACC will contribute less to the total figure than the Cost of Equity portion will.\n\nHowever, the above is true only to a certain point. Once a company\'s debt goes up high enough, the interest rate will rise dramatically to reflect the additional risk and so the Cost of Debt would start to increase - if it gets high enough, it might become higher than Cost of Equity and additional debt would increase WACC.\nIt\'s a "U-shape" curve where debt decreases WACC to a point, then starts increasing it.',
    type: "DCF",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "cb1ad723-307d-40fb-a006-21aa29de7246",
  },
  {
    question:
      "Which has a greater impact on a company's DCF valuation - a 10% change in\nrevenue or a 1% change in the discount rate?",
    answer:
      "You should start by saying, \"it depends\" but most of the time the 10% difference in revenue will have more of an impact. That change in revenue doesn't affect only the current year's revenue, but also the revenue/EBITDA far into the future and even the terminal value.",
    type: "DCF",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "d26e37d2-cc34-4c8a-92f3-0f59850abe84",
  },
  {
    question:
      "What about a 1% change in revenue vs. a 1% change in the discount rate?",
    answer:
      'In this case the discount rate is likely to have a bigger impact on the valuation, though the correct answer should start with, "It could go either way, but most of the time..."',
    type: "DCF",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "3b385004-8d9d-439c-a3d4-bd9181423442",
  },
  {
    question: "How do you calculate WACC for a private company?",
    answer:
      "This is problematic because private companies don't have market caps or Betas. In this case you would most likely just estimate WACC based on work done by auditors or valuation specialists, or based on what WACC for comparable public companies is.",
    type: "DCF",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "ef22a26c-fd8f-4bbf-ade2-08ce7de44c6a",
  },
  {
    question:
      "What should you do if you don't believe management's projections for a DCF model?",
    answer:
      "You can take a few different approaches:\n• You can create your own projections.\n• You can modify management's projections downward to make them more\nconservative.\n• You can show a sensitivity table based on different growth rates and margins and show the values assuming managements' projections and assuming a more conservative set of numbers.\nIn reality, you'd probably do all of these if you had unrealistic projections.",
    type: "DCF",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "9af957ff-6477-4018-a3a4-fe67a9f7a1d2",
  },
  {
    question:
      "Why would you not use a DCF for a bank or other financial institution?",
    answer:
      "Banks use debt differently than other companies and do not re-invest it in the business - they use it to create products instead. Also, interest is a critical part of banks' business models and working capital takes up a huge part of their Balance Sheets - so a DCF for a financial institution would not make much sense.\nFor financial institutions, it's more common to use a dividend discount model for valuation purposes.",
    type: "DCF",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "14c5ce4a-97e8-41ee-a5db-de2cbbb2154b",
  },
  {
    question: "What types of sensitivity analyses would we look at in a DCF?",
    answer:
      "Example sensitivities:\n• Revenue Growth vs. Terminal Multiple\n• EBITDA Margin vs. Terminal Multiple\n• Terminal Multiple vs. Discount Rate\n• Long-Term Growth Rate vs. Discount Rate\nAnd any combination of these (except Terminal Multiple vs. Long-Term Growth Rate, which would make no sense).\n\nThis compares enterprise value with different values of these numbers",
    type: "DCF",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "31176335-111a-42a6-8e69-8b105ed8af27",
  },
  {
    question:
      "A company has a high debt load and is paying off a significant portion of its principal each year. How do you account for this in a DCF?",
    answer:
      "Trick question. You don't account for this at all in a DCF, because paying off debt principal shows up in Cash Flow from Financing on the Cash Flow Statement - but we only go down to Cash Flow from Operations and then subtract Capital Expenditures to get to Free Cash Flow.\nIf we were looking at Levered Free Cash Flow, then our interest expense would decline in future years due to the principal being paid off - but we still wouldn't count the principal repayments themselves anywhere.",
    type: "DCF",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "43d2f774-00b2-4f79-be01-a1cf60e35dd1",
  },
  {
    question: "Is interest an operating expense",
    answer: "No, it's a non-operating expense.",
    type: "DCF",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "824ba0ed-d216-4cc6-b8fd-44238666c577",
  },
  {
    question: "Is depreciation an operating expense",
    answer: "Yes",
    type: "DCF",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "c33d1b57-2f30-4bf4-93d0-2a826e2a8027",
  },
  {
    question: "Does operating cash flow account for interest",
    answer: "yes because it starts with net income",
    type: "DCF",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "feb14ed4-7048-4625-b82c-1644f4dea4c8",
  },
  {
    question: "Three interpretations of WACC",
    answer: "opportunity cost, cost of capital, required rate of return",
    type: "DCF",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "2087e6e7-a9e1-495e-a14b-eab4957efb46",
  },
  {
    question: "Levered Beta =",
    answer: "Un-Levered Beta x (1 + ((1 - Tax Rate) x (Total Debt/Equity)))",
    type: "DCF",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "1f096175-4a3d-4b1c-b511-93ade5aa8fa2",
  },
  {
    question: "How does a increase in current liabilities affect DCF",
    answer: "Makes DCF higher, smaller NWC, subtract less",
    type: "DCF",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "9e8e4bdb-bcaa-417a-be86-7ce189f39a8b",
  },
  {
    question: "Decrease in current liabilities",
    answer:
      "Uses cash, increase in current assets uses cash, decrease in current assets gives you back cash",
    type: "DCF",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "05ac213b-57c1-4754-be9c-d43b0392fc53",
  },
  {
    question: "Explain why we would use the mid-year convention in a DCF.",
    answer:
      "You use it to represent the fact that a company's cash flow does not come 100% at the end of each year - instead, it comes in evenly throughout each year. \nIn a DCF without mid-year convention, we would use discount periods of 1 for the first year, 2 for the second year, and 3 for the third year, and so on. \nWith mid-year convention, we would instead use 0.5 for the first year, 1.5 for the second year, 2.5 for the third year, and so on.",
    type: "DCF",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "65cfaadb-dc85-4c72-b944-8fa8718fc778",
  },
  {
    question:
      "What discount period numbers would I use for the mid-year convention if I have a stub period - e.g. Q4 of Year 1 - in my DCF?",
    answer:
      'The rule is that you divide the stub period by 2, and then you simply subtract .5 from the "normal" discount periods for the future years.',
    type: "DCF",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "ac242115-e202-4368-bc05-973cd3065755",
  },
  {
    question:
      "How does the terminal value calculation change when we use the mid-year convention?",
    answer:
      "When you're discounting the terminal value back to the present value, you use different numbers for the discount period depending on whether you're using the Multiples Method or Gordon Growth Method:\nMultiples Method: You add 0.5 to the final year discount number to reflect the fact that you're assuming the company gets sold at the end of the year. \nGordon Growth Method: You use the final year discount number as is, because you're assuming the cash flows grow into perpetuity and that they are still received throughout the year rather than just at the end.",
    type: "DCF",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "54fa4e28-a648-4d60-8a48-280e7f064565",
  },
  {
    question:
      "If I'm working with a public company in a DCF, how do I calculate its per-share value?",
    answer:
      "Once you get to Enterprise Value, ADD cash and then subtract debt, preferred stock, and minority interest (and any other debt-like items) to get to Equity Value. \nThen, you need to use a circular calculation that takes into account the basic shares outstanding, warrants, convertibles, and other dilutive securities. It's circular because the dilution from these depends on the per-share price - but the per-share price depends on number of shares outstanding, which depends on the per-share price. \nTo resolve this, you need to enable iterative calculations in Excel so that it can cycle through to find an approximate per-share price.",
    type: "DCF",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "d80083d3-0942-4d30-a890-85fcb26658e1",
  },
  {
    question:
      "Walk me through a Dividend Discount Model (DDM) that you would use in place of a normal DCF for financial institutions.",
    answer:
      "The mechanics are the same as a DCF, but we use dividends rather than free cash flows: \n\n1. Project out the company's earnings, down to earnings per share (EPS). \n2. Assume a dividend payout ratio - what percentage of the EPS actually gets paid out to shareholders in the form of dividends - based on what the firm has done historically and how much regulatory capital it needs. \n3. Use this to calculate dividends over the next 5-10 years. \n4. Discount each dividend to its present value based on Cost of Equity - NOT WACC - and then sums these up. \n5. Calculate terminal value based on P/E and EPS in the final year, and then discount this to its present value based on Cost of Equity. \n6. Sum the present value of the terminal value and the present values of the dividends to get the company's net present per-share value.",
    type: "DCF",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "c2a209f9-36bd-4e34-b69d-99b68017a640",
  },
  {
    question:
      "When you're calculating WACC, let's say that the company has convertible debt. Do you count this as debt when calculating Levered Beta for the company?",
    answer:
      "Trick question: If the convertible bond is in-the-money then you do not count it as debt but instead assume that it contributes to dilution, so the company's Equity Value is higher. If it's out-of-the money then you count it as debt and use the interest rate on the convertible for Cost of Debt.",
    type: "DCF",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "a633180c-b93a-4c24-9e9f-a657f107710b",
  },
  {
    question:
      "We're creating a DCF for a company that is planning to buy a factory for $100 in cash (no debt or other financing) in Year 4. Currently the present value of its Enterprise Value according to the DCF is $200. How would we change the DCF to account for the factory purchase, and what would our new Enterprise Value be?",
    answer:
      'In this scenario, you would add CapEx spending of $100 in year 4 of the DCF, which would reduce Free Cash Flow in that year by $100. The Enterprise Value, in turn, would fall by the present value of that $100 decrease in Free Cash Flow. \nThe actual math here is messy but you would calculate the present value by dividing $100 by ((1 + Discount Rate)^4 - the "4" just represents year 4 here. Then you would subtract this amount from the Enterprise Value.',
    type: "DCF",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "731a3e97-1ddf-4608-8892-c334328de155",
  },
  {
    question: "Walk me through a basic merger model.",
    answer:
      "\"A merger model is used to analyze the financial profiles of 2 companies, the purchase price and how the purchase is made, and determines whether the buyer's EPS increases or decreases.\n\nStep 1 is making assumptions about the acquisition - the price and whether it was cash, stock or debt or some combination of those. Next, you determine the valuations and shares outstanding of the buyer and seller and project out an Income Statement for each\none.\n\nFinally, you combine the Income Statements, adding up line items such as Revenue and Operating Expenses, and adjusting for Foregone Interest on Cash and Interest Paid on Debt in the Combined Pre-Tax Income line; you apply the buyer's Tax Rate to get the Combined Net Income, and then divide by the new share count to determine the combined EPS.\"",
    type: "Merger Model (M&A)",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "d8151200-0c92-4c0a-9fd6-64cbc62ee34c",
  },
  {
    question: "What's the difference between a merger and an acquisition?",
    answer:
      'There\'s always a buyer and a seller in any M&A deal - the difference between "merger" and "acquisition" is more semantic than anything. In a merger the companies are close\nto the same size, whereas in an acquisition the buyer is significantly larger.',
    type: "Merger Model (M&A)",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "24b354e7-a0bd-4712-a76f-47fa78dccd7f",
  },
  {
    question: "Why would a company want to acquire another company?",
    answer:
      'Several possible reasons:\n\n• The buyer wants to gain market share by buying a competitor.\n• The buyer needs to grow more quickly and sees an acquisition as a way to do that.\n• The buyer believes the seller is undervalued.\n• The buyer wants to acquire the seller\'s customers so it can up-sell and cross-sell to them.\n• The buyer thinks the seller has a critical technology, intellectual property or some other "secret sauce" it can use to significantly enhance its business.\n• The buyer believes it can achieve significant synergies and therefore make the deal accretive for its shareholders.',
    type: "Merger Model (M&A)",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "29a074fe-f2ba-478a-860e-2c999d3580ec",
  },
  {
    question: "Why would an acquisition be dilutive?",
    answer:
      "An acquisition is dilutive if the additional amount of Net Income the seller contributes is not enough to offset the buyer's foregone interest on cash, additional interest paid on debt, and the effects of issuing additional shares.\n\nAcquisition effects - such as amortization of intangibles - can also make an acquisition\ndilutive.",
    type: "Merger Model (M&A)",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "a7a51290-587f-4042-9345-b46e32ce7de2",
  },
  {
    question:
      "Is there a rule of thumb for calculating whether an acquisition will be accretive or\ndilutive?",
    answer:
      "If the deal involves just cash and debt, you can sum up the interest expense for debt and the foregone interest on cash, then compare it against the seller's Pre-Tax Income.\n\nAnd if it's an all-stock deal you can use a shortcut to assess whether it is accretive (see question #5).\n\nBut if the deal involves cash, stock, and debt, there's no quick rule-of-thumb you can use unless you're lightning fast with mental math.",
    type: "Merger Model (M&A)",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "a86d30c6-c47c-4b49-b92c-ad088a15fddd",
  },
  {
    question:
      "A company with a higher P/E acquires one with a lower P/E - is this accretive or\ndilutive?",
    answer:
      "Trick question. You can't tell unless you also know that it's an all-stock deal. If it's an all-cash or all-debt deal, the P/E multiples of the buyer and seller don't matter because\nno stock is being issued.\n\nSure, generally getting more earnings for less is good and is more likely to be accretive but there's no hard-and-fast rule unless it's an all-stock deal.",
    type: "Merger Model (M&A)",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "ed0b4dda-6d27-4991-9015-f8df4f9feecd",
  },
  {
    question:
      "What is the rule of thumb for assessing whether an M&A deal will be accretive or\ndilutive?",
    answer:
      "In an all-stock deal, if the buyer has a higher P/E than the seller, it will be accretive; if the buyer has a lower P/E, it will be dilutive.\n\nOn an intuitive level if you're paying more for earnings than what the market values your own earnings at, you can guess that it will be dilutive; and likewise, if you're paying less for earnings than what the market values your own earnings at, you can guess that it would be accretive.",
    type: "Merger Model (M&A)",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "cfd8fb4a-b436-4a66-a84d-48880465a4db",
  },
  {
    question: "What are the complete effects of an acquisition?",
    answer:
      "1. Foregone Interest on Cash - The buyer loses the Interest it would have otherwise earned if it uses cash for the acquisition.\n2. Additional Interest on Debt - The buyer pays additional Interest Expense if it uses debt.\n3. Additional Shares Outstanding - If the buyer pays with stock, it must issue additional shares.\n4. Combined Financial Statements - After the acquisition, the seller's financials are added to the buyer's.\n5. Creation of Goodwill & Other Intangibles - These Balance Sheet items that represent a \"premium\" paid to a company's \"fair value\" also get created.\n\nNote: There's actually more than this (see the advanced questions), but this is usually sufficient to mention in interviews.",
    type: "Merger Model (M&A)",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "fb23831e-0ffb-46ee-9ae9-5e8626a4ccde",
  },
  {
    question:
      "If a company were capable of paying 100% in cash for another company, why would it choose NOT to do so?",
    answer:
      'It might be saving its cash for something else or it might be concerned about running\nlow if business takes a turn for the worst; its stock may also be trading at an all-time high and it might be eager to use that instead (in finance terms this would be "more expensive" but a lot of executives value having a safety cushion in the form of a large cash balance).',
    type: "Merger Model (M&A)",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "c1b47cbb-4886-4391-a920-d9351edb7b17",
  },
  {
    question:
      "Why would a strategic acquirer typically be willing to pay more for a company than a private equity firm would?",
    answer:
      "Because the strategic acquirer can realize revenue and cost synergies that the private equity firm cannot unless it combines the company with a complementary portfolio\ncompany. Those synergies boost the effective valuation for the target company.",
    type: "Merger Model (M&A)",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "606adce6-aedd-4e54-b8c5-759ef4f2e339",
  },
  {
    question:
      "Why do Goodwill & Other Intangibles get created in an acquisition?",
    answer:
      'These represent the value over the "fair market value" of the seller that the buyer has paid. You calculate the number by subtracting the book value of a company from its equity purchase price.\n\nMore specifically, Goodwill and Other Intangibles represent things like the value of customer relationships, brand names and intellectual property - valuable, but not true financial Assets that show up on the Balance Sheet.',
    type: "Merger Model (M&A)",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "3337b3df-92ba-48e9-9fbb-5084f1307b0a",
  },
  {
    question:
      "What is the difference between Goodwill and Other Intangible Assets?",
    answer:
      "Goodwill typically stays the same over many years and is not amortized. It changes only if there's goodwill impairment (or another acquisition).\n\nOther Intangible Assets, by contrast, are amortized over several years and affect the Income Statement by hitting the Pre-Tax Income line.\n\nThere's also a difference in terms of what they each represent, but bankers rarely go into that level of detail - accountants and valuation specialists worry about assigning each one to specific items.",
    type: "Merger Model (M&A)",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "98d252b8-0229-4536-a1ed-cb8ea912ed13",
  },
  {
    question:
      'Is there anything else "intangible" besides Goodwill & Other Intangibles that could also impact the combined company?',
    answer:
      'Yes. You could also have a Purchased In-Process R&D Write-off and a Deferred Revenue Write-off.\n\nThe first refers to any Research & Development projects that were purchased in the acquisition but which have not been completed yet. The logic is that unfinished R&D projects require significant resources to complete, and as such, the "expense" must be recognized as part of the acquisition.\n\nThe second refers to cases where the seller has collected cash for a service but not yet recorded it as revenue, and the buyer must write-down the value of the Deferred\nRevenue to avoid "double counting" revenue.',
    type: "Merger Model (M&A)",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "57c708e4-060e-4db5-acd4-d5fb7daea3ce",
  },
  {
    question: "What are synergies, and can you provide a few examples?",
    answer:
      "Synergies refer to cases where 2 + 2 = 5 (or 6, or 7...) in an acquisition. Basically, the buyer gets more value than out of an acquisition than what the financials would predict.\n\nThere are 2 types: revenue synergies and cost (or expense) synergies.\n\n• Revenue Synergies: The combined company can cross-sell products to new customers or up-sell new products to existing customers. It might also be able to expand into new geographies as a result of the deal.\n• Cost Synergies: The combined company can consolidate buildings and administrative staff and can lay off redundant employees. It might also be able to shut down redundant stores or locations.",
    type: "Merger Model (M&A)",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "a856b5fe-332c-48f0-abdf-539cd1e9c65b",
  },
  {
    question: "How are synergies used in merger models?",
    answer:
      "Revenue Synergies: Normally you add these to the Revenue figure for the combined company and then assume a certain margin on the Revenue - this additional Revenue\nthen flows through the rest of the combined Income Statement.\n\nCost Synergies: Normally you reduce the combined COGS or Operating Expenses by this amount, which in turn boosts the combined Pre-Tax Income and thus Net Income, raising the EPS and making the deal more accretive.",
    type: "Merger Model (M&A)",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "2da1c929-a1dc-40e1-ad3f-aee849a1ac95",
  },
  {
    question: "Are revenue or cost synergies more important?",
    answer:
      "No one in M&A takes revenue synergies seriously because they're so hard to predict. Cost synergies are taken a bit more seriously because it's more straightforward to see how buildings and locations might be consolidated and how many redundant employees might be eliminated.\n\nThat said, the chances of any synergies actually being realized are almost 0 so few take them seriously at all.",
    type: "Merger Model (M&A)",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "f8ded7ce-d206-412f-91b9-c94e9b661e3f",
  },
  {
    question:
      "All else being equal, which method would a company prefer to use when acquiring\nanother company - cash, stock, or debt?",
    answer:
      'Assuming the buyer had unlimited resources, it would always prefer to use cash when buying another company. Why?\n\n• Cash is "cheaper" than debt because interest rates on cash are usually under 5% whereas debt interest rates are almost always higher than that. Thus, foregone\ninterest on cash is almost always less than additional interest paid on debt for the same amount of cash/debt.\n• Cash is also less "risky" than debt because there\'s no chance the buyer might fail to raise sufficient funds from investors.\n• It\'s hard to compare the "cost" directly to stock, but in general stock is the most "expensive" way to finance a transaction - remember how the Cost of Equity is almost always higher than the Cost of Debt? That same principle applies here.\n• Cash is also less risky than stock because the buyer\'s share price could change dramatically once the acquisition is announced.',
    type: "Merger Model (M&A)",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "003bec25-37c7-4a77-8b84-173a6de97ecf",
  },
  {
    question: "How much debt could a company issue in a merger or acquisition?",
    answer:
      "Generally you would look at Comparable Companies/ Precedent Transactions to\ndetermine this. You would use the combined company's LTM (Last Twelve Months) EBITDA figure, find the median Debt/EBITDA ratio of whatever companies you're looking at, and apply that to your own EBITDA figure to get a rough idea of how much debt you could raise.\n\nYou would also look at \"Debt Comps\" for companies in the same industry and see what types of debt and how many tranches they have used.",
    type: "Merger Model (M&A)",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "faf0a0ee-4d79-4066-901b-e3de600b8019",
  },
  {
    question:
      "How do you determine the Purchase Price for the target company in an acquisition?",
    answer:
      'You use the same Valuation methodologies we already discussed. If the seller is a\npublic company, you would pay more attention to the premium paid over the current share price to make sure it\'s "sufficient" (generally in the 15-30% range) to win\nshareholder approval.\n\nFor private sellers, more weight is placed on the traditional methodologies.',
    type: "Merger Model (M&A)",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "65bb4a54-3ef1-4e6b-825a-389fab6dfb0f",
  },
  {
    question:
      "Let's say a company overpays for another company - what typically happens\nafterwards and can you give any recent examples?",
    answer:
      "There would be an incredibly high amount of Goodwill & Other Intangibles created if the price is far above the fair market value of the company. Depending on how the\nacquisition goes, there might be a large goodwill impairment charge later on if the company decides it overpaid.\n\nA recent example is the eBay / Skype deal, in which eBay paid a huge premium and extremely high multiple for Skype. It created excess Goodwill & Other Intangibles, and eBay later ended up writing down much of the value and taking a large quarterly loss as\na result.",
    type: "Merger Model (M&A)",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "b30094df-a7ea-43d3-a920-3499bbd263b0",
  },
  {
    question:
      "A buyer pays $100 million for the seller in an all-stock deal, but a day later the market decides it's only worth $50 million. What happens?",
    answer:
      "The buyer's share price would fall by whatever per-share dollar amount corresponds to the $50 million loss in value. Note that it would not necessarily be cut in half.\n\nDepending on how the deal was structured, the seller would effectively only be receiving half of what it had originally negotiated.\n\nThis illustrates one of the major risks of all-stock deals: sudden changes in share price could dramatically impact valuation.",
    type: "Merger Model (M&A)",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "83661ee8-95cf-4b0b-85d5-2c8bd1279399",
  },
  {
    question: "Why do most mergers and acquisitions fail?",
    answer:
      "Like so many things, M&A is \"easier said than done.\" In practice it's very difficult to acquire and integrate a different company, actually realize synergies and also turn the acquired company into a profitable division.\n\nMany deals are also done for the wrong reasons, such as CEO ego or pressure from shareholders. Any deal done without both parties' best interests in mind is likely to fail.",
    type: "Merger Model (M&A)",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "86857376-e39f-489a-af63-356339f8385b",
  },
  {
    question: "What role does a merger model play in deal negotiations?",
    answer:
      'The model is used as a sanity check and is used to test various assumptions. A company would never decide to do a deal based on the output of a model.\n\nIt might say, "Ok, the model tells us this deal could work and be moderately accretive - it\'s worth exploring more."\n\nIt would never say, "Aha! This model predicts 21% accretion - we should definitely acquire them now!"\n\nEmotions, ego and personalities play a far bigger role in M&A (and any type of negotiation) than numbers do.',
    type: "Merger Model (M&A)",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "44adc7fe-13a3-439a-b585-d0c2e5b3a147",
  },
  {
    question:
      "What types of sensitivities would you look at in a merger model? What variables would you look at?",
    answer:
      "The most common variables to look at are Purchase Price, % Stock/Cash/Debt, Revenue Synergies, and Expense Synergies. Sometimes you also look at different operating sensitivities, like Revenue Growth or EBITDA Margin, but it's more common to build these into your model as different scenarios instead.\n\nYou might look at sensitivity tables showing the EPS accretion/dilution at different ranges for the Purchase Price vs. Cost Synergies, Purchase Price vs. Revenue Synergies, or Purchase Price vs. % Cash (and so on).",
    type: "Merger Model (M&A)",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "7322721b-6a23-498f-a21a-6a8261f11110",
  },
  {
    question:
      "What's the difference between Purchase Accounting and Pooling Accounting in an\nM&A deal?",
    answer:
      "In purchase accounting the seller's shareholders' equity number is wiped out and the premium paid over that value is recorded as Goodwill on the combined balance sheet post-acquisition. In pooling accounting, you simply combine the 2 shareholders' equity numbers rather than worrying about Goodwill and the related items that get created.\n\nThere are specific requirements for using pooling accounting, so in 99% of M&A deals you will use purchase accounting.",
    type: "Merger Model (M&A)",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "3e8ab5e1-093a-4586-ab3f-aa9072ff36a5",
  },
  {
    question:
      "Walk me through a concrete example of how to calculate revenue synergies.",
    answer:
      "\"Let's say that Microsoft is going to acquire Yahoo. Yahoo makes money from search advertising online, and they make a certain amount of revenue per search (RPS). Let's\nsay this RPS is $0.10 right now. If Microsoft acquired it, we might assume that they could boost this RPS by $0.01 or $0.02 because of their superior monetization. So to\ncalculate the additional revenue from this synergy, we would multiply this $0.01 or $0.02 by Yahoo's total # of searches, get the total additional revenue, and then select a\nmargin on it to determine how much flows through to the combined company's Operating Income.\"",
    type: "Merger Model (M&A)",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "8c4526e1-ab4d-4b00-8f8b-9c8c1c982b85",
  },
  {
    question:
      "Walk me through an example of how to calculate expense synergies.",
    answer:
      "\"Let's say that Microsoft still wants to acquire Yahoo!. Microsoft has 5,000 SG&A-related employees, whereas Yahoo has around 1,000. Microsoft calculates that post-transaction, it will only need about 200 of Yahoo's SG&A employees, and its existing employees can\ntake over the rest of the work. To calculate the Operating Expenses the combined company would save, we would multiply these 800 employees Microsoft is going to fire\npost-transaction by their average salary.\"",
    type: "Merger Model (M&A)",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "8d5593f5-098e-4284-a28f-68d530ef7bb0",
  },
  {
    question: "How do you take into account NOLs in an M&A deal?",
    answer:
      "You apply Section 382 to determine how much of the seller's NOLs are usable each year.\n\nAllowable NOLs = Equity Purchase Price * Highest of Past 3 Months' Adjusted Long Term Rates\n\nSo if our equity purchase price were $1 billion and the highest adjusted long-term rate were 5%, then we could use $1 billion * 5% = $50 million of NOLs each year.\n\nIf the seller had $250 million in NOLs, then the combined company could use $50 million of them each year for 5 years to offset its taxable income.\n\nYou can look at long-term rates right here: http://pmstax.com/afr/exemptAFR.shtml",
    type: "Merger Model (M&A)",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "c83526c7-89fd-4bfc-8ba9-b85b1c3bc2e4",
  },
  {
    question:
      "Why do deferred tax liabilities (DTLs) and deferred tax assets (DTAs) get created in\nM&A deals?",
    answer:
      "These get created when you write up assets - both tangible and intangible - and when you write down assets in a transaction. An asset write-up creates a deferred tax liability, and an asset write-down creates a deferred tax asset.\n\nYou write down and write up assets because their book value - what's on the balance sheet - often differs substantially from their \"fair market value.\"\n\nAn asset write-up creates a deferred tax liability because you'll have a higher depreciation expense on the new asset, which means you save on taxes in the short-term\n- but eventually you'll have to pay them back, hence the liability. The opposite applies for an asset write-down and a deferred tax asset.",
    type: "Merger Model (M&A)",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "9334918d-d855-4691-be64-3ffd7dc8f6f2",
  },
  {
    question:
      "How do DTLs and DTAs affect the Balance Sheet Adjustment in an M&A deal?",
    answer:
      "You take them into account with everything else when calculating the amount of Goodwill & Other Intangibles to create on your pro-forma balance sheet. The formulas\nare as follows:\n\nDeferred Tax Asset = Asset Write-Down * Tax Rate\nDeferred Tax Liability = Asset Write-Up * Tax Rate\n\nSo let's say you were buying a company for $1 billion with half-cash and half-debt, and you had a $100 million asset write-up and a tax rate of 40%. In addition, the seller has total assets of $200 million, total liabilities of $150 million, and shareholders' equity of\n$50 million.\n\nHere's what would happen to the combined company's balance sheet (ignoring transaction/financing fees):\n\n• First, you simply add the seller's Assets and Liabilities (but NOT Shareholders' Equity - it is wiped out) to the buyer's to get your \"initial\" balance sheet. Assets\nare up by $200 million and Liabilities are down by $150 million.\n• Then, Cash on the Assets side goes down by $500 million.\n• Debt on the Liabilities & Equity side goes up by $500 million.\n• You get a new Deferred Tax Liability of $40 million ($100 million * 40%) on the Liabilities & Equity side.\n• Assets are down by $300 million total and Liabilities & Shareholders' Equity are up by $690 million ($500 + $40 + $150).\n• So you need Goodwill & Intangibles of $990 million on the Assets side to make both sides balance.",
    type: "Merger Model (M&A)",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "76c627f1-632d-4b24-94a3-170b54c256d2",
  },
  {
    question: "Could you get DTLs or DTAs in an asset purchase?",
    answer:
      "No, because in an asset purchase the book basis of assets always matches the tax basis. They get created in a stock purchase because the book values of assets are written up or written down, but the tax values are not.",
    type: "Merger Model (M&A)",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "5e193c70-93c1-44e6-baf8-e3a09e110407",
  },
  {
    question:
      "How do you account for DTLs in forward projections in a merger model?",
    answer:
      'You create a book vs. cash tax schedule and figure out what the company owes in taxes based on the Pretax Income on its books, and then you determine what it actually pays in cash taxes based on its NOLs and newly created amortization and depreciation\nexpenses (from any asset write-ups).\n\nAnytime the "cash" tax expense exceeds the "book" tax expense you record this as an decrease to the Deferred Tax Liability on the Balance Sheet; if the "book" expense is higher, then you record that as an increase to the DTL.',
    type: "Merger Model (M&A)",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "e8683347-3024-4d81-84bd-55af562b192d",
  },
  {
    question:
      "Explain the complete formula for how to calculate Goodwill in an M&A deal.",
    answer:
      "Goodwill = Equity Purchase Price - Seller Book Value + Seller's Existing Goodwill - Asset Write-Ups - Seller's Existing Deferred Tax Liability + Write-Down of Seller's\nExisting Deferred Tax Asset + Newly Created Deferred Tax Liability\n\nA couple notes here:\n• Seller Book Value is just the Shareholders' Equity number.\n• You add the Seller's Existing Goodwill because it gets written down to $0 in an M&A deal.\n• You subtract the Asset Write-Ups because these are additions to the Assets side of the Balance Sheet - Goodwill is also an asset, so effectively you need less\nGoodwill to \"plug the hole.\"\n• Normally you assume 100% of the Seller's existing DTL is written down.\n• The seller's existing DTA may or may not be written down completely (see the next question).",
    type: "Merger Model (M&A)",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "94c60e5d-0255-4b30-b294-5b8bff78e348",
  },
  {
    question:
      "Explain why we would write down the seller's existing Deferred Tax Asset in an\nM&A deal.",
    answer:
      "You write it down to reflect the fact that Deferred Tax Assets include NOLs, and that you might use these NOLs post-transaction to offset the combined entity's taxable income.\n\nIn an asset or 338(h)(10) purchase you assume that the entire NOL balance goes to $0 in the transaction, and then you write down the existing Deferred Tax Asset by this NOL write-down.\n\nIn a stock purchase the formula is:\n\nDTA Write-Down = Buyer Tax Rate  MAX(0, NOL Balance - Allowed Annual NOL Usage  Expiration Period in Years)\n\nThis formula is saying, \"If we're \ngoing to use up all these NOLs post transaction, let's not write anything down. Otherwise, let's write down the portion that we cannot actually use post-transaction, i.e. whatever our existing NOL balance is minus the amount we can use per year times the number of years.\"",
    type: "Merger Model (M&A)",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "ad78409a-722b-492a-9d3b-e748022214fc",
  },
  {
    question:
      "What's a Section 338(h)(10) election and why might a company want to use it in an\nM&A deal?",
    answer:
      "A Section 338(h)(10) election blends the benefits of a stock purchase and an asset purchase:\n\n• Legally it is a stock purchase, but accounting-wise it's treated like an asset purchase.\n• The seller is still subject to double-taxation - on its assets that have appreciated and on the proceeds from the sale.\n• But the buyer receives a step-up tax basis on the new assets it acquires, and it can depreciate/amortize them so it saves on taxes.\n\nEven though the seller still gets taxed twice, buyers will often pay more in a 338(h)(10) deal because of the tax-savings potential. It's particularly helpful for:\n\n• Sellers with high NOL balances (more tax-savings for the buyer because this NOL balance will be written down completely - and so more of the excess purchase price can be allocated to asset write-ups).\n• If the company has been an S-corporation for over 10 years - in this case it doesn't have to pay a tax on the appreciation of its assets.\n\nThe requirements to use 338(h)(10) are complex and bankers don't deal with this - that is the role of lawyers and tax accountants.",
    type: "Merger Model (M&A)",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "a2fbde40-543d-4442-b8cc-61cf6c1c99cb",
  },
  {
    question:
      "What is an exchange ratio and when would companies use it in an M&A deal?",
    answer:
      "An exchange ratio is an alternate way of structuring a 100% stock M&A deal, or any M&A deal with a portion of stock involved.\n\nLet's say you were going to buy a company for $100 million in an all-stock deal. Normally you would determine how much stock to issue by dividing the $100 million by the buyer's stock price, and using that to get the new share count.\n\nWith an exchange ratio, by contrast, you would tie the number of new shares to the buyer's own shares - so the seller might receive 1.5 shares of the buyer's shares for each\nof its shares, rather than shares worth a specific dollar amount.\n\nBuyers might prefer to do this if they believe their stock price is going to decline posttransaction - sellers, on the other hand, would prefer a fixed dollar amount in stock unless they believe the buyer's share price will rise after the transaction.",
    type: "Merger Model (M&A)",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "2ffdd740-43c5-4cb4-bead-2c79d4042e61",
  },
  {
    question:
      "Walk me through the most important terms of a Purchase Agreement in an M&A\ndeal.",
    answer:
      'There are dozens, but here are the most important ones:\n\n• Purchase Price: Stated as a per-share amount for public companies.\n• Form of Consideration: Cash, Stock, Debt...\n• Transaction Structure: Stock, Asset, or 338(h)(10)\n• Treatment of Options: Assumed by the buyer? Cashed out? Ignored?\n• Employee Retention: Do employees have to sign non-solicit or non-compete agreements? What about management?\n• Reps & Warranties: What must the buyer and seller claim is true about their respective businesses?\n• No-Shop / Go-Shop: Can the seller "shop" this offer around and try to get a better deal, or must it stay exclusive to this buyer?',
    type: "Merger Model (M&A)",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "1e866684-4d5b-4d75-97d9-03e990bbcb45",
  },
  {
    question:
      "What's an Earnout and why would a buyer offer it to a seller in an M&A deal?",
    answer:
      'An Earnout is a form of "deferred payment" in an M&A deal - it\'s most common with private companies and start-ups, and is highly unusual with public sellers.\n\nIt is usually contingent on financial performance or other goals - for example, the buyer might say, "We\'ll give you an additional $10 million in 3 years if you can hit $100\nmillion in revenue by then."\n\nBuyers use it to incentivize sellers to continue to perform well and to discourage management teams from taking the money and running off to an island in the South Pacific once the deal is done.',
    type: "Merger Model (M&A)",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "10f3dd30-bf0b-4112-8613-acaac977c244",
  },
  {
    question:
      "How would an accretion / dilution model be different for a private seller?",
    answer:
      "The mechanics are the same, but the transaction structure is more likely to be an asset purchase or 338(h)(10) election; private sellers also don't have Earnings Per Share so you would only project down to Net Income on the seller's Income Statement.\n\nNote that accretion / dilution makes no sense if you have a private buyer because private companies do not have Earnings Per Share.",
    type: "Merger Model (M&A)",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "ca4e92d9-a7e3-4aa1-8bb3-e71d1756b5da",
  },
  {
    question:
      'How would I calculate "break-even synergies" in an M&A deal and what does the\nnumber mean?',
    answer:
      "To do this, you would set the EPS accretion / dilution to $0.00 and then back-solve in Excel to get the required synergies to make the deal neutral to EPS.\n\nIt's important because you want an idea of whether or not a deal \"works\" mathematically, and a high number for the break-even synergies tells you that you're\ngoing to need a lot of cost savings or revenue synergies to make it work.",
    type: "Merger Model (M&A)",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "82b88143-955f-4228-9df5-cc5e8d0fef50",
  },
  {
    question:
      "Normally in an accretion / dilution model you care most about combining both\ncompanies' Income Statements. But let's say I want to combine all 3 financial statements - how would I do this?",
    answer:
      "You combine the Income Statements like you normally would (see the previous question on this), and then you do the following:\n\n1. Combine the buyer's and seller's balance sheets (except for the seller's Shareholders' Equity number).\n2. Make the necessary Pro-Forma Adjustments (cash, debt, goodwill/intangibles, etc.).\n3. Project the combined Balance Sheet using standard assumptions for each item (see the Accounting section).\n4. Then project the Cash Flow Statement and link everything together as you normally would with any other 3-statement model.",
    type: "Merger Model (M&A)",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "f053bbf7-a5fe-45ce-9ff3-a2ad600dbcf9",
  },
  {
    question:
      "How do you handle options, convertible debt, and other dilutive securities in a\nmerger model?",
    answer:
      'The exact treatment depends on the terms of the Purchase Agreement - the buyer might\nassume them or it might allow the seller to "cash them out" assuming that the per-share purchase price is above the exercise prices of these dilutive securities.\n\nIf you assume they\'re exercised, then you calculate dilution to the equity purchase price in the same way you normally would - Treasury Stock Method for options, and assume that convertibles convert into normal shares using the conversion price.',
    type: "Merger Model (M&A)",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "64b91771-3298-4b78-86f4-8b83365adc6d",
  },
  {
    question:
      "What are the main 3 transaction structures you could use to acquire another\ncompany?",
    answer:
      "Stock Purchase, Asset Purchase, and 338(h)(10) Election. The basic differences:\n\nStock Purchase:\n\n• Buyer acquires all asset and liabilities of the seller as well as off-balance sheet items.\n• The seller is taxed at the capital gains tax rate.\n• The buyer receives no step-up tax basis for the newly acquired assets, and it can't depreciate/amortize them for tax purposes.\n• A Deferred Tax Liability gets created as a result of the above.\n• Most common for public companies and larger private companies.\n\nAsset Purchase:\n\n• Buyer acquires only certain assets and assumes only certain liabilities of the seller and gets nothing else.\n• Seller is taxed on the amount its assets have appreciated (what the buyer is paying for each one minus its book value) and also pays a capital gains tax on the\nproceeds.\n• The buyer receives a step-up tax basis for the newly acquired assets, and it can depreciate/amortize them for tax purposes.\n• No Deferred Tax Liability is created as a result of the above.\n• Most common for private companies, divestitures, and distressed public companies.\n\nSection 338(h)(10) Election:\n\n• Buyer acquires all asset and liabilities of the seller as well as off-balance sheet items.\n• Seller is taxed on the amount its assets have appreciated (what the buyer is paying for each one minus its book value) and also pays a capital gains tax on the\nproceeds.\n• The buyer receives a step-up tax basis for the newly acquired assets, and it can depreciate/amortize them for tax purposes.\n• No Deferred Tax Liability is created as a result of the above.\n• Most common for private companies, divestitures, and distressed public companies.\n• To compensate for the buyer's favorable tax treatment, the buyer usually agrees to pay more than it would in an Asset Purchase.",
    type: "Merger Model (M&A)",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "fef6816d-1ed8-40f7-95a9-6d3ae8336089",
  },
  {
    question:
      "Would a seller prefer a stock purchase or an asset purchase? What about the buyer?",
    answer:
      "A seller almost always prefers a stock purchase to avoid double taxation and to get rid of all its liabilities. The buyer almost always prefers an asset deal so it can be more careful about what it acquires and to get the tax benefit from being able to deduct depreciation and amortization of asset write-ups for tax purposes.",
    type: "Merger Model (M&A)",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "64eeac1c-bce5-4274-b212-a135f72f37a3",
  },
  {
    question:
      "Explain what a contribution analysis is and why we might look at it in a merger\nmodel.",
    answer:
      'A contribution analysis compares how much revenue, EBITDA, Pre-Tax Income, cash, and possibly other items the buyer and seller are "contributing" to estimate what the\nownership of the combined company should be.\n\nFor example, let\'s say that the buyer is set to own 50% of the new company and the seller is set to own 50%. But the buyer has $100 million of revenue and the seller has $50 million of revenue - a contribution analysis would tell us that the buyer "should" own 66% instead because it\'s contributing 2/3 of the combined revenue.\n\nIt\'s most common to look at this with merger of equals scenarios, and less common when the buyer is significantly larger than the seller.',
    type: "Merger Model (M&A)",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "5209fdef-2867-4f23-bf1f-81bc899ad3bf",
  },
  {
    question:
      "How do you account for transaction costs, financing fees, and miscellaneous expenses in a merger model?",
    answer:
      'In the "old days" you used to capitalize these expenses and then amortize them; with the new accounting rules, you\'re supposed to expense transaction and miscellaneous fees upfront, but capitalize the financing fees and amortize them over the life of the debt.\n\nExpensed transaction fees come out of Retained Earnings when you adjust the Balance Sheet, while capitalized financing fees appear as a new Asset on the Balance Sheet and are amortized each year according to the tenor of the debt.',
    type: "Merger Model (M&A)",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "77f327e1-3619-446b-bac6-def03d7206e3",
  },
  {
    question: "Walk me through a basic LBO model.",
    answer:
      "\"In an LBO Model, Step 1 is making assumptions about the Purchase Price, Debt/Equity ratio, Interest Rate on Debt and other variables; you might also assume something about the company's operations, such as Revenue Growth or Margins, depending on how\nmuch information you have.\n\nStep 2 is to create a Sources & Uses section, which shows how you finance the transaction and what you use the capital for; this also tells you how much Investor Equity is required.\n\nStep 3 is to adjust the company's Balance Sheet for the new Debt and Equity figures, and also add in Goodwill & Other Intangibles on the Assets side to make everything balance.\n\nIn Step 4, you project out the company's Income Statement, Balance Sheet and Cash Flow Statement, and determine how much debt is paid off each year, based on the available Cash Flow and the required Interest Payments.\n\nFinally, in Step 5, you make assumptions about the exit after several years, usually assuming an EBITDA Exit Multiple, and calculate the return based on how much equity is returned to the firm.\"",
    type: "Leveraged Buyouts (LBOs)",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "60ab0bc6-bc35-4d1a-a9ef-02108dbfe3e4",
  },
  {
    question: "Why would you use leverage when buying a company?",
    answer:
      "To boost your return.\n\nRemember, any debt you use in an LBO is not \"your money\" - so if you're paying $5 billion for a company, it's easier to earn a high return on $2 billion of your own money and $3 billion borrowed from elsewhere vs. $3 billion of your own money and $2 billion\nof borrowed money.\n\nA secondary benefit is that the firm also has more capital available to purchase other companies because they've used leverage.",
    type: "Leveraged Buyouts (LBOs)",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "501bdecc-fe39-411e-a10b-cabf7759298c",
  },
  {
    question: "What variables impact an LBO model the most?",
    answer:
      "Purchase and exit multiples have the biggest impact on the returns of a model. After that, the amount of leverage (debt) used also has a significant impact, followed by operational characteristics such as revenue growth and EBITDA margins.",
    type: "Leveraged Buyouts (LBOs)",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "cc97367a-5393-490d-979e-ba68a988a7db",
  },
  {
    question:
      "How do you pick purchase multiples and exit multiples in an LBO model?",
    answer:
      "The same way you do it anywhere else: you look at what comparable companies are trading at, and what multiples similar LBO transactions have had. As always, you also show a range of purchase and exit multiples using sensitivity tables.\n\nSometimes you set purchase and exit multiples based on a specific IRR target that you're trying to achieve - but this is just for valuation purposes if you're using an LBO model to value the company.",
    type: "Leveraged Buyouts (LBOs)",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "21b6e4db-ca28-437e-95c0-5445d5aefbce",
  },
  {
    question: 'What is an "ideal" candidate for an LBO?',
    answer:
      '"Ideal" candidates have stable and predictable cash flows, low-risk businesses, not much need for ongoing investments such as Capital Expenditures, as well as an\nopportunity for expense reductions to boost their margins. A strong management team also helps, as does a base of assets to use as collateral for debt.\n\nThe most important part is stable cash flow.',
    type: "Leveraged Buyouts (LBOs)",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "f26ccb29-d6ea-499e-8444-4a6a4f89668e",
  },
  {
    question:
      'How do you use an LBO model to value a company, and why do we sometimes say\nthat it sets the "floor valuation" for the company?',
    answer:
      'You use it to value a company by setting a targeted IRR (for example, 25%) and then back-solving in Excel to determine what purchase price the PE firm could pay to achieve that IRR.\n\nThis is sometimes called a "floor valuation" because PE firms almost always pay less for a company than strategic acquirers would.',
    type: "Leveraged Buyouts (LBOs)",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "42dd7b15-6c60-4d0f-b69f-5b31d96f4bef",
  },
  {
    question: 'Give an example of a "real-life" LBO.',
    answer:
      "The most common example is taking out a mortgage when you buy a house. Here's how the analogy works:\n\n• Down Payment: Investor Equity in an LBO\n• Mortgage: Debt in an LBO\n• Mortgage Interest Payments: Debt Interest in an LBO\n• Mortgage Repayments: Debt Principal Repayments in an LBO\n• Selling the House: Selling the Company / Taking It Public in an LBO",
    type: "Leveraged Buyouts (LBOs)",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "98efc489-10e3-481e-b7f4-df470294056a",
  },
  {
    question:
      "Can you explain how the Balance Sheet is adjusted in an LBO model?",
    answer:
      'First, the Liabilities & Equities side is adjusted - the new debt is added on, and the Shareholders\' Equity is "wiped out" and replaced by however much equity the private\nequity firm is contributing.\n\nOn the Assets side, Cash is adjusted for any cash used to finance the transaction, and then Goodwill & Other Intangibles are used as a "plug" to make the Balance Sheet balance.\n\nDepending on the transaction, there could be other effects as well - such as capitalized financing fees added to the Assets side.',
    type: "Leveraged Buyouts (LBOs)",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "04f58426-3ad4-49a9-b70c-7c0b8f4cfe9f",
  },
  {
    question: "Why are Goodwill & Other Intangibles created in an LBO?",
    answer:
      'Remember, these both represent the premium paid to the "fair market value" of the company. In an LBO, they act as a "plug" and ensure that the changes to the Liabilities & Equity side are balanced by changes to the Assets side.',
    type: "Leveraged Buyouts (LBOs)",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "7fc51c6d-ae16-4c2b-89ad-5d7c91a0fdb1",
  },
  {
    question:
      "We saw that a strategic acquirer will usually prefer to pay for another company in cash - if that's the case, why would a PE firm want to use debt in an LBO?",
    answer:
      'It\'s a different scenario because:\n\n1. The PE firm does not intend to hold the company for the long-term - it usually sells it after a few years, so it is less concerned with the "expense" of cash vs. debt and more concerned about using leverage to boost its returns by reducing the amount of capital it has to contribute upfront.\n2. In an LBO, the debt is "owned" by the company, so they assume much of the risk. Whereas in a strategic acquisition, the buyer "owns" the debt so it is more risky\nfor them.',
    type: "Leveraged Buyouts (LBOs)",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "0853bdd8-e55c-4830-b995-ed6a11f5caca",
  },
  {
    question:
      'Do you need to project all 3 statements in an LBO model? Are there any "shortcuts?"',
    answer:
      "Yes, there are shortcuts and you don't necessarily need to project all 3 statements.\n\nFor example, you do not need to create a full Balance Sheet - bankers sometimes skip this if they are in a rush. You do need some form of Income Statement, something to track how the Debt balances change and some type of Cash Flow Statement to show\nhow much cash is available to repay debt.\n\nBut a full-blown Balance Sheet is not strictly required, because you can just make assumptions on the Net Change in Working Capital rather than looking at each item\nindividually.",
    type: "Leveraged Buyouts (LBOs)",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "1ff9d2b8-63ad-4bc0-b707-a05dcddf9328",
  },
  {
    question:
      "How would you determine how much debt can be raised in an LBO and how many tranches there would be?",
    answer:
      "Usually you would look at Comparable LBOs and see the terms of the debt and how many tranches each of them used. You would look at companies in a similar size range and industry and use those criteria to determine the debt your company can raise.",
    type: "Leveraged Buyouts (LBOs)",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "1b2f01bb-45e7-48ce-ae0f-0309c06ed2b5",
  },
  {
    question:
      "Let's say we're analyzing how much debt a company can take on, and what the terms of the debt should be. What are reasonable leverage and coverage ratios?",
    answer:
      'This is completely dependent on the company, the industry, and the leverage and coverage ratios for comparable LBO transactions.\n\nTo figure out the numbers, you would look at "debt comps" showing the types, tranches, and terms of debt that similarly sized companies in the industry have used recently.\n\nThere are some general rules: for example, you would never lever a company at 50x EBITDA, and even during the bubble leverage rarely exceeded 5-10x EBITDA.',
    type: "Leveraged Buyouts (LBOs)",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "d5032ad7-9bed-4b6e-8139-b1558b157f46",
  },
  {
    question: "What is the difference between bank debt and high-yield debt?",
    answer:
      'This is a simplification, but broadly speaking there are 2 "types" of debt: "bank debt" and "high-yield debt." There are many differences, but here are a few of the most\nimportant ones:\n\n• High-yield debt tends to have higher interest rates than bank debt (hence the name "high-yield").\n• High-yield debt interest rates are usually fixed, whereas bank debt interest rates are "floating" - they change based on LIBOR or the Fed interest rate.\n• High-yield debt has incurrence covenants while bank debt has maintenance covenants. The main difference is that incurrence covenants prevent you from doing\nsomething (such as selling an asset, buying a factory, etc.) while maintenance covenants require you to maintain a minimum financial performance (for example,\nthe Debt/EBITDA ratio must be below 5x at all times).\n• Bank debt is usually amortized - the principal must be paid off over time - whereas with high-yield debt, the entire principal is due at the end (bullet maturity).\n\nUsually in a sizable Leveraged Buyout, the PE firm uses both types of debt.\n\nAgain, there are many different types of debt - this is a simplification, but it\'s enough\nfor entry-level interviews.',
    type: "Leveraged Buyouts (LBOs)",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "11b0e941-6a19-4b7d-bc7f-03e88622a866",
  },
  {
    question:
      "Why might you use bank debt rather than high-yield debt in an LBO?",
    answer:
      "If the PE firm or the company is concerned about meeting interest payments and wants a lower-cost option, they might use bank debt; they might also use bank debt if they are planning on major expansion or Capital Expenditures and don't want to be restricted by incurrence covenants.",
    type: "Leveraged Buyouts (LBOs)",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "e24efef7-faf0-4ad5-ac13-ca0b7c5a8ec4",
  },
  {
    question: "Why would a PE firm prefer high-yield debt instead?",
    answer:
      "If the PE firm intends to refinance the company at some point or they don't believe their returns are too sensitive to interest payments, they might use high-yield debt. They\nmight also use the high-yield option if they don't have plans for major expansion or selling off the company's assets.",
    type: "Leveraged Buyouts (LBOs)",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "e7a975f4-b6cd-46b5-8c29-2585639dbf5e",
  },
  {
    question:
      'Why would a private equity firm buy a company in a "risky" industry, such as\ntechnology?',
    answer:
      'Although technology is more "risky" than other markets, remember that there are mature, cash flow-stable companies in almost every industry. There are some PE firms that specialize in very specific goals, such as:\n\n• Industry consolidation - buying competitors in a similar market and combining them to increase efficiency and win more customers.\n• Turnarounds - taking struggling companies and making them function properly again.\n• Divestitures - selling off divisions of a company or taking a division and turning it into a strong stand-alone entity.\n\nSo even if a company isn\'t doing well or seems risky, the firm might buy it if it falls into one of these categories.',
    type: "Leveraged Buyouts (LBOs)",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "b4f4fa50-53cb-4a7f-83dd-0b625d4c1fdb",
  },
  {
    question: "How could a private equity firm boost its return in an LBO?",
    answer:
      "1. Lower the Purchase Price in the model.\n2. Raise the Exit Multiple / Exit Price.\n3. Increase the Leverage (debt) used.\n4. Increase the company's growth rate (organically or via acquisitions).\n5. Increase margins by reducing expenses (cutting employees, consolidating buildings, etc.).\n\nNote that these are all \"theoretical\" and refer to the model rather than reality - inpractice it's hard to actually implement these.",
    type: "Leveraged Buyouts (LBOs)",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "df774dbe-bd7b-4980-8fac-2af16e3ead31",
  },
  {
    question: 'What is meant by the "tax shield" in an LBO?',
    answer:
      "This means that the interest a firm pays on debt is tax-deductible - so they save money on taxes and therefore increase their cash flow as a result of having debt from the LBO.\n\nNote, however, that their cash flow is still lower than it would be without the debt - saving on taxes helps, but the added interest expenses still reduces Net Income over what it would be for a debt-free company.",
    type: "Leveraged Buyouts (LBOs)",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "dca06676-d28c-48b0-8b5a-8b4ec759168e",
  },
  {
    question: 'What is a dividend recapitalization ("dividend recap")?',
    answer:
      "In a dividend recap, the company takes on new debt solely to pay a special dividend out to the PE firm that bought it.\n\nIt would be like if you made your friend take out a personal loan just so he/she could pay you a lump sum of cash with the loan proceeds.\n\nAs you might guess, dividend recaps have developed a bad reputation, though they're still commonly used.",
    type: "Leveraged Buyouts (LBOs)",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "709bb46e-b19c-435e-82d8-615ccce597eb",
  },
  {
    question:
      "Why would a PE firm choose to do a dividend recap of one of its portfolio\ncompanies?",
    answer:
      'Primarily to boost returns. Remember, all else being equal, more leverage means a higher return to the firm.\n\nWith a dividend recap, the PE firm is "recovering" some of its equity investment in the company - and as we saw earlier, the lower the equity investment, the better, since it\'s\neasier to earn a higher return on a smaller amount of capital.',
    type: "Leveraged Buyouts (LBOs)",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "dcd7aff0-9b50-40f3-b917-de2b2d2f8cca",
  },
  {
    question:
      "How would a dividend recap impact the 3 financial statements in an LBO?",
    answer:
      "No changes to the Income Statement. On the Balance Sheet, Debt would go up and Shareholders' Equity would go down and they would cancel each other out so that everything remained in balance.\n\nOn the Cash Flow Statement, there would be no changes to Cash Flow from Operations or Investing, but under Financing the additional Debt raised would cancel out the Cash paid out to the investors, so Net Change in Cash would not change.",
    type: "Leveraged Buyouts (LBOs)",
    subType: "Basic",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "bbfc5e3a-62dd-4359-8d3b-fb6b17185e93",
  },
  {
    question:
      "Tell me about all the different kinds of debt you could use in an LBO and the\ndifferences between everything.",
    answer:
      'Here\'s a handy chart to explain all of this. Note that this chart does not cover every single feature or every single type of debt in the universe - just the most important ones,\nand what you\'re likely to be asked about in finance interviews:\n\n[SEE CHART ON BIWS GUIDES]\n\n"Tenor" is just the fancy word for "How many years will this loan be outstanding?"\n\nEach type of debt is arranged in order of rising interest rates - so a revolver has the lowest interest rate, Term Loan A is slightly higher, B is slightly higher, Senior Notes are\nhigher than Term Loan B, and so on.\n\n"Seniority" refers to the order of claims on a company\'s assets in a bankruptcy - the Senior Secured holders are first in line, followed by Senior Unsecured, Senior Subordinated, and then Equity Investors.\n\n"Floating" or "Fixed" Interest Rates: A "floating" interest rate is tied to LIBOR. For example, L + 100 means that the interest rate of the loan is whatever LIBOR is at currently, plus 100 basis points (1.0%). A fixed interest rate, on the other hand, would be 11%. It doesn\'t "float" with LIBOR or any other rate.\n\nAmortization: "straight line" means the company pays off the principal in equal installments each year, while "bullet" means that the entire principal is due at the end of\nthe loan\'s lifecycle. "Minimal" just means a low percentage of the principal each year, usually in the 1-5% range.\n\nCall Protection: Is the company prohibited from "calling back" - paying off or redeeming - the security for a certain period? This is beneficial for investors because\nthey are guaranteed a certain number of interest payments.',
    type: "Leveraged Buyouts (LBOs)",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "a4986b83-bc5b-4e1c-8b3b-f4b0cf8fe6f2",
  },
  {
    question:
      "How would an asset write-up or write-down affect an LBO model? / Walk me through how you adjust the Balance Sheet in an LBO model.",
    answer:
      "All of this is very similar to what you would see in a merger model - you calculate Goodwill, Other Intangibles, and the rest of the write-ups in the same way, and then the Balance Sheet adjustments (e.g. subtracting cash, adding in capitalized financing fees, writing up assets, wiping out goodwill, adjusting the deferred tax assets / liabilities, adding in new debt, etc.) are almost the same.\n\nThe key differences: \n\n• In an LBO model you assume that the existing Shareholders' Equity is wiped out and replaced by the equity the private equity firm contributes to buy the company; you may also add in Preferred Stock, Management Rollover, or Rollover from Option Holders to this number as well depending on what you're assuming for transaction financing.\n• In an LBO model you'll usually be adding a lot more tranches of debt vs. what you would see in a merger model.\n• In an LBO model you're not combining two companies' Balance Sheets.",
    type: "Leveraged Buyouts (LBOs)",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "f62c8b32-050b-445b-88a1-5dcb9207904a",
  },
  {
    question:
      "Normally we care about the IRR for the equity investors in an LBO - the PE firm that buys the company - but how do we calculate the IRR for the debt investors?",
    answer:
      'For the debt investors, you need to calculate the interest and principal payments they receive from the company each year.\n\nThen you simply use the IRR function in Excel and start with the negative amount of the original debt for "Year 0," assume that the interest and principal payments each year are your "cash flows" and then assume that the remaining debt balance in the final year is your "exit value."\n\nMost of the time, returns for debt investors will be lower than returns for the equity investors - but if the deal goes poorly or the PE firm can\'t sell the company for a good price, the reverse could easily be true.',
    type: "Leveraged Buyouts (LBOs)",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "fd3242c9-c67d-4540-8dc2-daa8234380bd",
  },
  {
    question:
      "Why might a private equity firm allot some of a company's new equity in an LBO to\na management option pool, and how would this affect the model?",
    answer:
      "This is done for the same reason you have an Earnout in an M&A deal: the PE firm wants to incentivize the management team and keep everyone on-board until they exit the investment.\n\nThe difference is that there's no technical limit on how much management might receive\nfrom such an option pool: if they hit it out of the park, maybe they'll all become millionaires.\n\nIn your LBO model, you would need to calculate a per-share purchase price when the\nPE firm exits the investment, and then calculate how much of the proceeds go to the management team based on the Treasury Stock Method.\n\nAn option pool by itself would reduce the PE firm's return, but this is offset by the fact that the company should perform better with this incentive in place.",
    type: "Leveraged Buyouts (LBOs)",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "11df6ea1-e74b-4c8a-8bcc-7040f923d1e9",
  },
  {
    question:
      "Why you would you use PIK (Payment In Kind) debt rather than other types of debt, and how does it affect the debt schedules and the other statements?",
    answer:
      'Unlike "normal" debt, a PIK loan does not require the borrower to make cash interest payments - instead, the interest just accrues to the loan principal, which keeps going up over time. A PIK "toggle" allows the company to choose whether to pay the interest in cash or have it accrue to the principal (these have disappeared since the credit crunch).\n\nPIK is more risky than other forms of debt and carries with it a higher interest rate than traditional bank debt or high yield debt.\n\nAdding it to the debt schedules is similar to adding high-yield debt with a bullet maturity - except instead of assuming cash interest payments, you assume that the interest accrues to the principal instead.\n\nYou should then include this interest on the Income Statement, but you need to add back any PIK interest on the Cash Flow Statement because it\'s a non-cash expense.',
    type: "Leveraged Buyouts (LBOs)",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "99ad6982-f2b9-4ec4-a6e3-143b4aa2adbd",
  },
  {
    question:
      "What are some examples of incurrence covenants? Maintenance covenants?",
    answer:
      "Incurrence Covenants:\n\n• Company cannot take on more than $2 billion of total debt.\n• Proceeds from any asset sales must be earmarked to repay debt.\n• Company cannot make acquisitions of over $200 million in size.\n• Company cannot spend more than $100 million on CapEx each year.\n\nMaintenance Covenants:\n\n• Total Debt / EBITDA cannot exceed 3.0 x\n• Senior Debt / EBITDA cannot exceed 2.0 x\n• (Total Cash Payable Debt + Capitalized Leases) / EBITDAR cannot exceed 4.0 x\n• EBITDA / Interest Expense cannot fall below 5.0 x\n• EBITDA / Cash Interest Expense cannot fall below 3.0 x\n• (EBITDA - CapEx) / Interest Expense cannot fall below 2.0 x",
    type: "Leveraged Buyouts (LBOs)",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "5beca21c-9e11-471e-af64-e3975e5cd47a",
  },
  {
    question:
      "Just like a normal M&A deal, you can structure an LBO either as a stock purchase or as an asset purchase. Can you also use Section 338(h)(10) election?",
    answer:
      'In most cases, no - because one of the requirements for Section 338(h)(10) is that the buyer must be a C corporation. Most private equity firms are organized as LLCs or\nLimited Partnerships, and when they acquire companies in an LBO, they create an LLC shell company that "acquires" the company on paper.',
    type: "Leveraged Buyouts (LBOs)",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "8a687b8e-5f95-452e-a648-a88b42e19295",
  },
  {
    question:
      "Walk me through how you calculate optional repayments on debt in an LBO model.",
    answer:
      "First, note that you only look at optional repayments for Revolvers and Term Loans - high-yield debt doesn't have a prepayment option, so effectively it's always $0.\n\nFirst, you check how much cash flow you have available based on your Beginning Cash Balance, Minimum Cash Balance, Cash Flow Available for Debt Repayment from the Cash Flow Statement, and how much you use to make Mandatory Debt Repayments.\n\nThen, if you've used your Revolver at all you pay off the maximum amount that you can with the cash flow you have available.\n\nNext, for Term Loan A you assume that you pay off the maximum you can, taking into account that you've lost any cash flow you used to pay down the Revolver. You also\nneed to take into account that you might have paid off some of Term Loan A's principal as part of the Mandatory Repayments.\n\nFinally, you do the same thing for Term Loan B, subtracting from the \"cash flow available for debt repayment\" what you've already used up on the Revolver and Term\nLoan A. And just like Term Loan A, you need to take into account any Mandatory Repayments you've made so that you don't pay off more than the entire Term Loan B\nbalance.\n\nThe formulas here get very messy and depend on how your model is set up, but this is the basic idea for optional debt repayments.",
    type: "Leveraged Buyouts (LBOs)",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "db141b49-61f4-4be0-8d10-89341d5c3c0d",
  },
  {
    question: "Explain how a Revolver is used in an LBO model.",
    answer:
      "You use a Revolver when the cash required for your Mandatory Debt Repayments exceeds the cash flow you have available to repay them.\n\nThe formula is: Revolver Borrowing = MAX(0, Total Mandatory Debt Repayment - Cash Flow Available to Repay Debt).\n\nThe Revolver starts off \"undrawn,\" meaning that you don't actually borrow money and don't accrue a balance unless you need it - similar to how credit cards work.\n\nYou add any required Revolver Borrowing to your running total for cash flow available for debt repayment before you calculate Mandatory and Optional Debt Repayments.\n\nWithin the debt repayments themselves, you assume that any Revolver Borrowing from previous years is paid off first with excess cash flow before you pay off any Term Loans.",
    type: "Leveraged Buyouts (LBOs)",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "9d93021c-bc78-4d23-afe6-b012f07c3e49",
  },
  {
    question: "How would you adjust the Income Statement in an LBO model?",
    answer:
      'The most common adjustments:\n\n• Cost Savings - Often you assume the PE firm cuts costs by laying off employees, which could affect COGS, Operating Expenses, or both.\n• New Depreciation Expense - This comes from any PP&E write-ups in the transaction.\n• New Amortization Expense - This includes both the amortization from written-up intangibles and from capitalized financing fees.\n• Interest Expense on LBO Debt - You need to include both cash and PIK interest here.\n• Sponsor Management Fees - Sometimes PE firms charge a "management fee" to a company to account for the time and effort they spend managing it.\n• Common Stock Dividend - Although private companies don\'t pay dividends to shareholders, they could pay out a dividend recap to the PE investors.\n• Preferred Stock Dividend - If Preferred Stock is used as a form of financing in the transaction, you need to account for Preferred Stock Dividends on the Income\nStatement.\n\nCost Savings and new Depreciation / Amortization hit the Operating Income line; Interest Expense and Sponsor Management Fees hit Pre-Tax Income; and you need to\nsubtract the dividend items from your Net Income number.',
    type: "Leveraged Buyouts (LBOs)",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "1c975035-f37d-465a-bc61-a7ea7c85ce91",
  },
  {
    question:
      "In an LBO model, is it possible for debt investors to get a higher return than the PE\nfirm? What does it tell us about the company we're modeling?",
    answer:
      "Yes, and it happens more commonly than you'd think. Remember, high-yield debt investors often get interest rates of 10-15% or more - which effectively guarantees an IRR in that range for them.\n\nSo no matter what happens to the company or the market, that debt gets repaid and thedebt investors get the interest payments.\n\nBut let's say that the median EBITDA multiples contract, or that the company fails to grow or actually shrinks - in these cases the PE firm could easily get an IRR below what the debt investors get.",
    type: "Leveraged Buyouts (LBOs)",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "25643507-9001-4d63-a5b8-8bb36a3e0b77",
  },
  {
    question:
      "Most of the time, increased leverage means an increased IRR. Explain how increasing the leverage could reduce the IRR.",
    answer:
      'This scenario is admittedly rare, but it could happen if the increase leverage increases interest payments or debt repayments to very high levels, preventing the company from using its cash flow for other purposes.\n\nSometimes in LBO models, increasing the leverage increases the IRR up to a certain point - but then after that the IRR starts falling as the interest payments or principal repayments become "too big."\n\nFor this scenario to happen you would need a "perfect storm" of:\n\n1. Relative lack of cash flow / EBITDA growth.\n2. High interest payments and principal repayments relative to cash flow.\n3. Relatively high purchase premium or purchase multiple to make it more difficult to get a high IRR in the first place.',
    type: "Leveraged Buyouts (LBOs)",
    subType: "Advanced",
    collection: "IB Fundamentals",
    output: "output",
    credit: "Breaking into Wall St.",
    id: "0a981c75-9f51-4a46-abc3-0cd6192ad349",
  },
]
